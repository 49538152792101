import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  Typography,
  Toolbar,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { SideMenuStyles } from "./SideMenuStyles";
import { FaBuilding, FaUserSlash } from "react-icons/fa";
import { setSecureItem, setSessionItem } from "../../services/Commons";
import { GetUtilitiesCorreosIdUserAccessControl } from "../../services/UtilitiesCallsService";
import { checkIsNullUndefined } from "../../commons/Utils";
import { EnumPages } from "../../commons/EnumPages";
import { ProfileBar } from "../ProfileBar/ProfileBar";
import { UserMenu } from "./components/UserMenu";
import { EnterpriseMenu } from "./components/EnterpriseMenu";
import { useTranslation } from "react-i18next";
import history from "../../services/history";
import { secureValues } from "../../commons/EnumsGeneral";

export function SideMenu(props) {
  const {
    updateEnterprises,
    updateUser,
    setUpdateEnterprises,
    setUpdateUser,
    setShowSideMenu,
    showSideMenu,
    setResetPage,
    resetPage,
    setIsSpecialUser,
    isSpecialUser
  } = props;
  const classes = SideMenuStyles();
  const { t, i18n } = useTranslation();

  const [user, setUser] = useState("");
  const [enterpriseList, setEnterpriseList] = useState([]);
  const [profileProgress, setProfileProgress] = useState(0);
  const [subMenuKey, setSubMenuKey] = useState("");
  const [circularProgress, setCircularProgress] = useState(true);
  const [selected, setSelected] = useState(null);

  const handleListItemClick = (item) => {
    setSelected(item === selected ? null : item);
  };

  useEffect(() => {
    if (updateEnterprises) {
      setUpdateEnterprises(false);
    }
    if (updateUser) {
      setUpdateUser(false);
    }
    handleSideMenuData();
  }, [updateEnterprises, updateUser]);

  const handleSideMenuData = () => {
    let serviceAuxiliaryOptionsValues = [];
    setCircularProgress(true);
    GetUtilitiesCorreosIdUserAccessControl().then((response) => {
      let auxiliaryArray = [];
      if (response && !response.Status) {
        let name = checkIsNullUndefined(response.name)
          ? t("userNameUnregistered")
          : response.name;
        setUser(name);
        serviceAuxiliaryOptionsValues.push({ name: name });
        let isUserTitular = false;
        response.userEnterpriseAssociated.forEach((enterprise, index) => {
          let enterpriseName = !checkIsNullUndefined(enterprise.companyName)
            ? enterprise.companyName
            : !checkIsNullUndefined(enterprise.tradeName)
              ? enterprise.tradeName : t("enterpriseNameUnregistered");
          let auxOption = {
            oid: enterprise.id,
            name: enterpriseName,
            index: index,
            idRol: enterprise.idRol,
          };
          auxiliaryArray.push(auxOption);
          serviceAuxiliaryOptionsValues.push(auxOption);
          if (enterprise.idRol == 1) {
            isUserTitular = true;
          };
        });
        setSessionItem(secureValues.isUserTitular, isUserTitular);
        setProfileProgress(response.percentage);
        setEnterpriseList(auxiliaryArray);
        setSecureItem(secureValues.enterpriseList, response.userEnterpriseAssociated);
        let auxSpecialUser = checkIsNullUndefined(response.special) ? false : response.special;
        setIsSpecialUser(auxSpecialUser);
      };
      setCircularProgress(false);
    });
  };

  const handleCreateEnterpriseClick = () => {
    setShowSideMenu();
    setSubMenuKey(null);
    setResetPage(!resetPage);
    handleListItemClick(t("create"));
    history.push("/Index/" + EnumPages.EnterpriseCreate);
  };

  const handleUserCancelAccountClick = () => {
    setShowSideMenu();
    setSubMenuKey(null);
    handleListItemClick(t("askUnsubscribeService"));
    history.push("/Index/" + EnumPages.UserCancelAccount);
  };

  const handleSubMenuKeys = (key) => {
    setSubMenuKey(key);
  };

  return (
    <Drawer
      className={showSideMenu ? classes.drawer : classes.hideDrawer}
      PaperProps={{ elevation: 6 }}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <Grid className={classes.userGrid}>
          <Typography variant="h6" noWrap className={classes.drawerText}>
            {t("profileComp")}
          </Typography>
          <ProfileBar
            progress={profileProgress}
            tooltip={t("profileComp")}
            className={classes.profileBar}
          />
        </Grid>
        <Grid style={{ height: "35em" }}>
          {circularProgress ? (
            <CircularProgress />
          ) : (
            <Grid>
              <UserMenu
                user={user}
                setShowSideMenu={setShowSideMenu}
                setSubMenuKey={handleSubMenuKeys}
                subMenuKey={subMenuKey}
                setSelected={setSelected}
                setResetPage={setResetPage}
                resetPage={resetPage}
                isSpecialUser={isSpecialUser}
              ></UserMenu>
              {enterpriseList.map((enterprise, index) => {
                return (
                  <EnterpriseMenu
                    key={index}
                    enterprise={enterprise}
                    setShowSideMenu={setShowSideMenu}
                    setSubMenuKey={handleSubMenuKeys}
                    subMenuKey={subMenuKey}
                    setSelected={setSelected}
                    setResetPage={setResetPage}
                    resetPage={resetPage}
                    isSpecialUser={isSpecialUser}
                  ></EnterpriseMenu>
                );
              })
              }
              {(checkIsNullUndefined(isSpecialUser) || !isSpecialUser) ? (
                <List key={"create"} className={classes.menuList}>
                  <ListItem
                    button
                    // key={t("create")}
                    onClick={() => handleCreateEnterpriseClick()}
                    className={classes.menuListItem}
                    selected={selected === t("create")}

                  >
                    <ListItemIcon className={classes.drawerText}>
                      <FaBuilding className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("create")}
                      primaryTypographyProps={{ noWrap: true }}
                    />
                  </ListItem>
                </List>
              ) : null}
              <List key={"unsubscribe"} className={classes.menuList}>
                <ListItem
                  button
                  // key={t("askUnsubscribeService")}
                  onClick={() => handleUserCancelAccountClick()}
                  className={classes.menuListItem}
                  selected={selected === t("askUnsubscribeService")}
                >
                  <ListItemIcon className={classes.drawerText}>
                    <FaUserSlash className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("askUnsubscribeService")}
                    primaryTypographyProps={{ noWrap: true }}
                  />
                </ListItem>
              </List>
            </Grid>
          )}
        </Grid>
      </div>
    </Drawer>
  );
}
