export const EU2 = {

    //User Account Cancel Page

    accountCancelDisableAccountAsk: "¿Seguro que quieres darte de baja?",
    accountCancelDisableAccountTitle:
        "Correos ID-n baja eman ondoren, ezin izango dituzu ondoko zerbitzu hauek guztiak erabili:",
    accountCancelDisableAccountListTitle: "Apuntatu hori:",
    accountCancelDisableAccountList: "Zure kontua ezabatuko dugu.",
    accountCancelDisableAccountP1:
        "Zure Correos ID kontua ezabatu baino lehen, erregistratuta zauden zerbitzuetan baja eskatu eta prozesua amaitzea gomendatzen dizugu. Hala ere, zure Correos ID kontua orain ezabatzea nahiago baduzu, egin klik Eman Baja aukeran.",
    accountCancelDisableAccountP2:
        "*Lasai, {disableAccDays} egun dituzu, damutuz gero, zure Correos ID kontua berreskuratzeko. Zure Correos ID kontua berreskuratu ahal izango duzu, baina baliteke beste zerbitzuek ez gordetzea zure datuak {disableAccDays} egun horien bitartean.",
    accountCancelDisableAccountConfirm: "Eman baja",
    accountCancelDisableAccountLastWarn: "Si pulsas en aceptar confirmarás que quieres darte de baja",

    //Enterprise Delete Page

    enterpriseDeleteEnterpriseTitle: "Ziur {enterpriseName} enpresa ezabatu nahi duzula?",
    enterpriseDeleteEnterpriseTitleNoName: "Ziur enpresa ezabatu nahi duzula?",
    enterpriseDeleteEnterpriseServicesTitle: "Enpresa honek zerbitzu hauetarako sarbidea du gaur egun:",
    enterpriseDeleteEnterpriseText: "Ezabatu ondoren, ezin izango dituzu hari dagozkion datuak berreskuratu",
    enterpriseDeleteButtonText: "Ezabatu enpresa",
    enterpriseDeleteAlertMessageSuccess: "Enpresa ondo ezabatu da",
    enterpriseDeleteAlertMessageError: "Errorea enpresa ezabatzean",
    enterpriseDeleteAlertLastWarning: "Si pulsas en aceptar confirmarás que quieres borrar la empresa",

    //Privacy page

    privacyPermissionsManage: "Egiaztatu zehaztapenak eta baldintzak",
    privacyPrivacyManage: "Kudeatu zure pribatutasuna",
    privacyUnsuscribe: "Kendu sarbidea nire datuetara",
    privacySuscribe: "Onartu sarbidea nire datuetara",
    privacyDownload: "Deskargatu nire pribatutasun-historikoa",
    privacySuccessChange: "Pribatutasuna behar bezala aldatu da",
    privacyWarning: "Kontuz!",
    privacyInfo1:
        "Nire Postontzitik Correos ID konturako sarbidea ezabatu behar duzu. Sartzeko baimena berriz eman beharko duzu Nire Postontzira joan nahi izanez gero. Onartzen duzun unetik aurrera, ez ditugu zure datuak zerbitzuarekin partekatuko.",
    privacyInfo1_PARAM:
        "{APP}(e)tik Correos ID konturako sarbidea ezabatu behar duzu. Sartzeko baimena berriz eman beharko duzu {APP}(e)ra joan nahi izanez gero. Onartzen duzun unetik aurrera, ez ditugu zure datuak zerbitzuarekin partekatuko.",
    privacyInfo2:
        "Nire Postontziak zure kontua ezaba dezan nahi baduzu, baja kudeatzen lagunduko dizugu hemen klikatuz.",
    noState: "Egoera gabe",
    privacyTyCLink: "Zeintzak eta baldintzak",
    privacyPrivacyLink: "Políticas de Privacidad",
    privacySignedTyCPrivacy: "Sinatua:",
    privacyIntroText: "Revisa los términos y condiciones, así como las poíticas de privacidad firmadas para cada servicio suscrito.",
    privacyIntroText2: "Gestiona la privacidad de Correos ID. Puedes revisar el estado actual de la protección de datos y modificarlo si así lo requieres.",
    privacyDataProtectionTitle: "Protección de Datos",
    privacyEnableAllText: "Activar todos",
    privacyDisableAllText: "Desactivar todos",

    //Team Page

    holderName: "Izena",
    holderLastName: "1. abizena",
    holderSecondLastName: "2. abizena",
    holderPrefix: "Aurrezenbakia",
    holderMobile: "Mugikorra",
    holderEmail: "Helbide elektronikoa",
    teamPageHolderTitle: "Kontuaren titularra",
    teamPageAuthorizedTitle: "Baimendutako pertsonak",
    teamPageAuthorizedName: "Izena",
    teamPageAuthorizedLastName: "Abizenak",
    teamPageAuthorizedState: "Egoera",
    teamPageAuthorizedRol: "Rola",
    teamPageButtonAddEmployee: "Gehitu Langilea",
    teamPageButtonChangeHolder: "Aldatu Titularra",
    teamPageInsertMail: "Helbide elektronikoa",
    teamPageInsertNewMail: "Idatzi helbide elektronikoa eta rola",
    teamPageSelectHolder: "Hautatu titular berria",
    teamPageRol: "Rola:",
    teamPageItemAdmin: "Administratzailea",
    teamPageItemAutorized: "Baimendua",
    teamPageChangeHolder: "Aldatu Titularra",
    teamPageInvitationSuccess: "Gonbidapena behar bezala bidali da",
    teamPageTitularChangeSuccess: "Titularra behar bezala aldatu da",
    teamPageTitularEmpty: "Enpresak ez du titularrik",
    revokeAdminPermissions: "Quitar persmisos de administrador",
    revokeAdminPermissionsQuestion:
        "Si continúas, quitarás permisos de administrador a este empleado, pero seguirá siendo empleado y perteneciendo al equipo",
    revokeAdminConfirm: "Permisos quitados correctamente",
    teamPageAddAdminTitle: "Dar permisos de administrador",
    teamPageAddAdminConfirmMsg:
        "Se va a enviar un email para que el usuario acepte el rol de administrador.",
    teamPageSubtitle: "Consulta y gestiona el equipo de la empresa",

    //unifyAccounts Page

    unifyAccountsTitle: "Unificación de cuentas",
    unifyAccountsPreviousInfoTitle: "Información previa",
    unifyAccountsPreviousInfo: "Información que puede ser de ayuda de cada usuario",
    unifyAccountsSubTitle: "Selecciona de entre los datos de usuario que son distintos entre ambas cuentas con cuál de ellos prefieres quedarte:",
    unifyAccountsDataTitle: "Datos de Usuario",
    unifyAccountsData1: "Datos de {user1}",
    unifyAccountsData2: "Datos de {user2}",
    unifyAccountsMultidata: "Ahora selecciona si hay alguno de los emails, teléfonos o direcciones de {user2} que quieres añadir en el perfil de {user1}",
    unifyAccountsMultidataTitle: "Emails, teléfonos y direcciones",
    unifyAccountsEmails1: "Emails de {user1}",
    unifyAccountsEmails2: "Emails de {user2}",
    unifyAccountsPhones1: "Teléfonos de {user1}",
    unifyAccountsPhones2: "Teléfonos de {user2}",
    unifyAccountsAddress1: "Direcciónes de {user1}",
    unifyAccountsAddress2: "Direcciónes de {user2}",
    unifyAccountsInfo1: "Información de {user1}",
    unifyAccountsInfo2: "Información de {user2}",
    unifyAccountsEnterpriseTitle: "Empresas",
    unifyAccountsEnterprise: "Aquí puedes ver las empresas de {user1} y las que se van a unificar del {user2}",
    unifyAccountsEnteprise1: "Empresas de {user1}",
    unifyAccountsEnteprise2: "Empresas de {user2}",
    unifyAccountsServiceTitle: "Servicios",
    unifyAccountsService: "Aquí puedes ver las suscripciones de {user1} y las aplicaciones a las que se va a suscribir de {user2}",
    unifyAccountsService1: "Aplicaciones suscritas de {user1}",
    unifyAccountsService2: "Aplicaciones suscritas de {user2}",
    unifyAccountsNoData: "Este usuario no tiene datos de este tipo",
    unifyAccountsSuccess: "Unificación finalizada",
    unifyAccountsText: "Las dos cuentas se han unificado, ya puedes seguir navegando",
    unifyAccountsRedirectWarn: "Tienes que indicar la pantalla a la que hay que redirigir",
    unifyAccountIncorrectId: "El identificador de usuario proporcionado no corresponde con ninguna cuenta",
    unifyAccountsMobileNoDoc: "Sin documento",
    unifyAccountsMobileuserData: "Escoge el usuario cuyos datos quieras conservar",
    unifyAccountsMobileuserDocument: "Escoge qué documento quieres conservar",
    unifyAccountsMobileuserEmail: "Selecciona los emails de {user2} que quieras incorporar",
    unifyAccountsMobileuserPhone: "Selecciona los teléfonos de {user2} que quieras incorporar",
    unifyAccountsMobileuserAddress: "Selecciona las direcciones de {user2} que quieras incorporar",
    unifyAccountsMobileuserEnterprise: "Estas son todas las empresas que tendrás",
    unifyAccountsMobileuserSubscription: "Estás son todas las aplicaciones a las que estarás suscrito",
    unifyAccountsMobileuserInfo: "Aquí aportamos información útil de cada usuario",
    unifyAccountsMobileUserInfoLogin: "Último login: ",
    unifyAccountsUserInfoLogin: "Último login",

    //Mobile unifyAccouts

    userName: "Nombre",
    userSurname1: "Apellido 1",
    userSurname2: "Apellido 2",
    userbirthDate: "Fecha de nacimiento",
    userNacionality: "Nacionalidad",
    userDocument: "NIF",
    userGender: "Sexo",
    userLanguage: "Idioma",
    stepPage: "Paso {stepPage} de {totalSteps}",
    userInfoMobile: "Información útil",
    userDataMobile: "Datos de usuario",
    emailsMobile: "Emails",
    phonesMobile: "Teléfonos",
    documentsMobile: "Documentos",
    addressMobile: "Direcciones",
    servicesMobile: "Suscripciones",
    enterpriseMobile: "Empresas",
    nextStep: "Siguiente",
    previousStep: "Anterior",
    saveMobile: "Guardar",

    //UploadDocumentModal

    uploadDocumentModalContent:
        "Mesedez, kontuan izan tipologia bereko dokumenturen bat egiaztapen-prozesuan badago, galdu egingo dela.",
    uploadDocumentModalTitle: "Dokumentu berri bat igo behar duzu",
    uploadDocumentFormatUnsupported: "Dokumentu-formatua ez da onartzen.",
    uploadDocumentSuccess: "Dokumentua behar bezala igo da",
    uploadDocumentError: "Arazo bat jazo da fitxategia igotzean",

    // AtcPage - Stepper

    atcSearchUser: "Bilatu erabiltzailea",
    atcSelectAccount: "Hautatu/Sortu kontua",
    atcUserVerify: "Egiaztatu erabiltzailea",
    atcUserData: "Erabiltzailearen datuak",
    atcIdentifierField:
        "Idatzi: NANa, pasaportea, telefono-zenbakia edo helbide elektronikoa",
    atcOtpText: "Zure identitatea bermatzeko, kode bat bidaliko dizugu:",
    atcSendEmailButton: "BIDALI MEZU ELEKTRONIKOA",
    atcResendEmailButton: "BIRBIDALI MEZU ELEKTRONIKOA",
    atcSendSmsButton: "BIDALI SMS-A",
    atcResendSmsButton: "BIRBIDALI SMS-A",
    atcRegisterPagePrefix: "Aurrezenbakia",
    atcRegisterPagePhone: "Telefonoa",
    atcRegisterPageEmail: "Helbide elektronikoa",
    atcRegisterPageDniNie: "NANa, pasaportea",
    atcRegisterPageSendOTP: "Idatzi bidali dizugun kodea",
    atcRegisterPageSendConsentSms: "BIDALI BALDINTZEN SMS-A",
    atcRegisterPageResendConsentSms: "BIRBIDALI BALDINTZEN SMS-A",
    atcCheckOtpCode: "EGIAZTATU KODEA",
    atcAccept: "ONARTU",
    atcClose: "ITXI",
    atcCancel: "BALIOGABETU",
    atcCreate: "SORTU",
    atcModify: "ALDATU",
    atcVerifyDocumentIdentifier: "EGIAZTATU NANa/PASAPORTEA",
    atcPhoneError: "Telefonoa nahitaezkoa da. Idatzi formatu egokian",
    atcIdentifierDocumentError:
        "NANa/pasaportea nahitaezkoa da. Idatzi formatu egokian",
    atcEmailError: "Helbide elektronikoa nahitaezkoa da. Idatzi formatu egokian",
    atcNewAccount: "Sortu kontu berri bat",
    atcRadioSelectAccount: "Hautatu zure kontua edo “Kontu Berria” aukera",
    atcModalTitle: "Egiaztatutako datuak",
    atcModalSubtitle: "Mesedez, hautatu zure kontua",
    atcModalEmailTitle: "Helbide Elektronikoak:",
    atcModalPhoneTitle: "Telefono-zenbakiak:",

    //DialogMissingData

    dialogMissingDataText: "Hay acciones requeridas para el correcto funcionamiento de la aplicación:",
    dialogMissingDataTextTyC: "Para ello antes debes aceptar la privacidad y los términos y condiciones",
    dialogMissingDataTextDocument: "Necesitas verificar tu documento identificativo lo que implica que no puedes continuar a partir de aquí. Para poder verificarlo te recomendamos que te dirijas a tu oficina de correos más cercana.",
    dialogMissingDataTextNoHolder: "La empresa no tiene los datos obligatorios necesarios, ponte en contacto con el titular para poder continuar.",
    dialogMissingDataunsuscribedEnterprise: "Es necesario suscribirse a la aplicación",
    dialogMissingDatapendingEnterprisePrivacy: "Para ello antes debes aceptar la privacidad y los términos y condiciones",
    dialogMissingDataincompleteUserData: "Rellenar datos de usuario obligatorios",
    dialogMissingDataincompleteEnterpriseData: "Rellenar datos de empresa obligatorios",
    dialogMissingDatapendingEnterprise: "Crear una empresa",
    dialogMissingDataverifyEmail: "Añadir un email verificado",
    dialogMissingDataverifyPhone: "Añadir un teléfono verificado",
    dialogMissingDataverifyAddress: "Añadir una dirección verificada",
    dialogMissingUserDataText:
        "Zerbitzuak behar bezala funtzionatu ahal izateko eskatzen diren datuak ez dira zuzenak edo ez dira guztiz bete. Mesedez, eguneratu zure datuak.",
    dialogMissingEnterpriseDataText:
        "Enpresa bat sortu behar da zerbitzu honek behar bezala funtziona dezan. Mesedez, sortu enpresa bat.",

    // Redirect Page

    redirectPageMissingParams: "Faltan parámetros",
    redirectPagePageNotFound: "La página no existe",

    // EnterpriseOauthLogin Page

    enterpriseOauthLoginPageError: "Faltan parámetros",
    redirectPageIdEnterpriseNotFound: "Se necesita el Id de empresa para acceder a esa página",
    redirectPageNotUserEnterprise: "La empresa indicada no pertenece al usuario",

    // ChooseEnterprise Page

    chooseEnterpriseNoSubsTitle: "Empresa no suscrita",
    chooseEnterpriseNoSubsText: "La empresa no está suscrita a la aplicación, ponte en contacto con el titular para que la suscriba y poder continuar",
    chooseEnterpriseNoPrivTitle: "Privacidad sin firmar",
    chooseEnterpriseNoPrivText: "La empresa no tiene firmada la privacidad, ponte en contacto con el titular para que la firme y poder continuar",
    chooseEnterpriseNoDataTitle: "Datos requeridos",
    chooseEnterpriseNoDataText: "La empresa no tiene todos los datos necesarios, ponte en contacto con el titular para que los rellene y poder continuar",
    
    // ResetPass Warn

    expiredPassTitle: "Contraseña caducada",
    expiredPassText: "La contraseña ha caducado, por favor, crea una nueva",

    //UserVerifications2FA
  verifications2FATitle: "Configura tu doble factor de autenticación",
  verifications2FAImageText: "Aquí puedes gestionar tus servicios con doble factor de autenticación. Activa o desactiva el doble factor de autenticación para cada uno de los servicios que tengas contratados teniendo en cuenta que hay servicios que no podrás desactivar porque lo exigen por normativa.",
  verifications2FAConfigText: "Servicios contratados",
  verifications2FASetPhone: "Configura tu teléfono",
  verifications2FASetEmailTitle: "Confirma tu identidad",
  verifications2FASetEmailText: "Mandaremos un código de verificación a tu email",
  verifications2FASetEmailConfText: "Mandaremos un código de verificación a tu email para confirmar tu identidad",
  verifications2FASetEmailOtp: "Código de verificación",
  verifications2FASetPhoneTitle: "Configura tu teléfono",
  verifications2FASetPhoneText: "Mandaremos un código de verificación a tu teléfono",
  verifications2FASetPhoneOtp: "Código de verificación",
  verifications2FASetPhonConfirmation: "Ya tienes configurado un teléfono para el doble factor de autenticación. Si estabas intentando hacer login en un servicio, por favor, vuelve a intentarlo.",
  verifications2FAConfigSuccess: "Tu configuración para el doble factor de autenticación se ha actualizado correctamente",

  // Login2FA

  login2FASetPhoneTitle: "Configura tu teléfono",
  login2FASetPhoneText: "Esta aplicación requiere un doble factor de autenticación y necesitas configurar tu teléfono. Para ello accede a la configuración de tu cuenta pulsando en 'Aceptar'.",
  login2FASendPhoneTitle: "Segundo factor de autenticación",
  login2FASendPhoneText: "Esta aplicación requiere un segundo factor de autenticación, introduce el código de verificación que enviaremos a tu teléfono.",
  login2FASetPhoneOtp: "Código de verificación",
  login2FASetPhoneError: "No se ha podido validar el teléfono. Por favor, inténtalo de nuevo.",

  // Oauth - ACM
  loginACMTitle: "Inicio de sesión erróneo",
  loginACMText: "Ha habido un problema con los datos aportados, por favor inténtalo de nuevo",
  generalACMTitle: "Atención",
  generalACMText: "Para poder acceder a la aplicación debes volver a iniciar sesión",

  // GeneralWarning
  generalWarningPassText: "Errore bat gertatu da zure pasahitza aldatzean. Balidazio-kodea jaso baduzu, baliteke gaizki sartu izana, edo agian sartu duzun {dataType} okerra izan da, kasu horretan, ziurtatu behar bezala sartu duzula.",
  generalWarningRegistryText: "Errore bat gertatu da kontua sortzean. Baliozkotze-kodea jaso baduzu, beharbada gaizki sartu duzu, edo agian adierazi duzun posta elektronikoko kontua CorreosID-en erregistratuta egotea izan daiteke, kasu horretan pasahitza berreskuratu beharko zenuke.",
  generalWarningVerifyText: "Errore bat gertatu da {dataType} egiaztatzean. Balidazio-kodea jaso baduzu, agian gaizki sartu duzu, edo agian adierazi duzun {dataType} CorreosID-en egiaztatuta egotea izan daiteke.",
};
