export const EN2 = {
    
    //User Account Cancel Page

    accountCancelDisableAccountAsk: "¿Seguro que quieres darte de baja?",
    accountCancelDisableAccountTitle: "If you cancel your Correos ID account you will no longer have access to the following services:",
    accountCancelDisableAccountListTitle: "Note that:",
    accountCancelDisableAccountList: "We will delete your account.",
    accountCancelDisableAccountP1: "Before you delete your Correos ID account we advise you to request and finalise the cancellation of the services where you are registered. If you would prefer to delete your Correos ID account now, click on Delete Account.",
    accountCancelDisableAccountP2: "*Don’t worry, you have {disableAccDays} days to recover your Correos ID account if you change your mind. You can recover the Correos ID account, but the other services may not save your data during these {disableAccDays} days.",
    accountCancelDisableAccountConfirm: "Delete Account",
    accountCancelDisableAccountLastWarn: "Si pulsas en aceptar confirmarás que quieres darte de baja",
  
    //Enterprise Delete Page

    enterpriseDeleteEnterpriseTitle: "Are you sure you want to delete enterprise {enterpriseName} ?",
    enterpriseDeleteEnterpriseTitleNoName: "Are you sure you want to delete the company?",
    enterpriseDeleteEnterpriseServicesTitle: "This company currently has access to the following services:",
    enterpriseDeleteEnterpriseText: "Once deleted you will not be able to recover the data referring to it",
    enterpriseDeleteButtonText: "Delete Company",
    enterpriseDeleteAlertMessageSuccess: "Enterprise Deleted Successfully",
    enterpriseDeleteAlertMessageError: "Error deleting enterprise",
    enterpriseDeleteAlertLastWarning: "Si pulsas en aceptar confirmarás que quieres borrar la empresa",
  
    //Privacy page

    privacyPermissionsManage: "Check the terms and conditions",
    privacyPrivacyManage: "Manage your privacy",
    privacyUnsuscribe: "Remove access to my details",
    privacySuscribe: "Permit access to my details",
    privacyDownload: "Download my privacy history",
    privacySuccessChange: "Privacy changed successfully",
    privacyWarning: "Attention!",
    privacyInfo1:
      "You are going to stop Mi Buzón (My Postbox) from accessing your Correos ID account. You will have to grant access again if you want to log into Mi Buzón. From the moment you click accept, we will no longer share your data with the service.",
    privacyInfo1_PARAM:
      "You are going to stop {APP} from accessing your Correos ID account. You will have to grant access again if you want to log into {APP}. From the moment you click accept, we will no longer share your data with the service.",
    privacyInfo2:
      "If you want Mi Buzón to delete your account, we can help you manage the deletion if you click here.",
    noState: "Without status",
    privacyTyCLink: "Terms and Conditions",
    privacyPrivacyLink: "Políticas de Privacidad",
    privacySignedTyCPrivacy: "Signed:",
    privacyIntroText: "Revisa los términos y condiciones, así como las poíticas de privacidad firmadas para cada servicio suscrito.",
    privacyIntroText2: "Gestiona la privacidad de Correos ID. Puedes revisar el estado actual de la protección de datos y modificarlo si así lo requieres.",
    privacyDataProtectionTitle: "Protección de Datos",
    privacyEnableAllText: "Activar todos",
    privacyDisableAllText: "Desactivar todos",

    //Team Page

    holderName: "First name",
    holderLastName: "1st Surname",
    holderSecondLastName: "2nd Surname",
    holderPrefix: "Prefix",
    holderMobile: "Mobile number",
    holderEmail: "Email",
    teamPageHolderTitle: "Account holder",
    teamPageAuthorizedTitle: "My authorised persons",
    teamPageAuthorizedName: "First name",
    teamPageAuthorizedLastName: "Surname(s)",
    teamPageAuthorizedState: "Status",
    teamPageAuthorizedRol: "Role",
    teamPageButtonAddEmployee: "Add Employee",
    teamPageButtonChangeHolder: "Change Holder",
    teamPageInsertMail: "Email",
    teamPageInsertNewMail: "Enter email address and role",
    teamPageSelectHolder: "Select the new holder",
    teamPageRol: "Role:",
    teamPageItemAdmin: "Administrator",
    teamPageItemAutorized: "Authorised person",
    teamPageChangeHolder: "Change Holder",
    teamPageInvitationSuccess: "Invitation sent successfully",
    teamPageTitularChangeSuccess: "Holder changed successfully",
    teamPageTitularEmpty: "The company does not have an account holder",
    revokeAdminPermissions: "Quitar persmisos de administrador",
    revokeAdminPermissionsQuestion:
      "Si continúas, quitarás permisos de administrador a este empleado, pero seguirá siendo empleado y perteneciendo al equipo",
    revokeAdminConfirm: "Permisos quitados correctamente",
    teamPageAddAdminTitle: "Dar permisos de administrador",
    teamPageAddAdminConfirmMsg:
      "Se va a enviar un email para que el usuario acepte el rol de administrador.",
    teamPageSubtitle: "Consulta y gestiona el equipo de la empresa",
  
    //unifyAccounts Page

    unifyAccountsTitle: "Unificación de cuentas",
    unifyAccountsPreviousInfoTitle: "Información previa",
    unifyAccountsPreviousInfo: "Información que puede ser de ayuda de cada usuario",
    unifyAccountsSubTitle: "Selecciona de entre los datos de usuario que son distintos entre ambas cuentas con cuál de ellos prefieres quedarte:",
    unifyAccountsDataTitle: "Datos de Usuario",
    unifyAccountsData1: "Datos de {user1}",
    unifyAccountsData2: "Datos de {user2}",
    unifyAccountsMultidata: "Ahora selecciona si hay alguno de los emails, teléfonos o direcciones de {user2} que quieres añadir en el perfil de {user1}",
    unifyAccountsMultidataTitle: "Emails, teléfonos y direcciones",
    unifyAccountsEmails1: "Emails de {user1}",
    unifyAccountsEmails2: "Emails de {user2}",
    unifyAccountsPhones1: "Teléfonos de {user1}",
    unifyAccountsPhones2: "Teléfonos de {user2}",
    unifyAccountsAddress1: "Direcciónes de {user1}",
    unifyAccountsAddress2: "Direcciónes de {user2}",
    unifyAccountsInfo1: "Información de {user1}",
    unifyAccountsInfo2: "Información de {user2}",
    unifyAccountsEnterpriseTitle: "Empresas",
    unifyAccountsEnterprise: "Aquí puedes ver las empresas de {user1} y las que se van a unificar del {user2}",
    unifyAccountsEnteprise1: "Empresas de {user1}",
    unifyAccountsEnteprise2: "Empresas de {user2}",
    unifyAccountsServiceTitle: "Servicios",
    unifyAccountsService: "Aquí puedes ver las suscripciones de {user1} y las aplicaciones a las que se va a suscribir de {user2}",
    unifyAccountsService1: "Aplicaciones suscritas de {user1}",
    unifyAccountsService2: "Aplicaciones suscritas de {user2}",
    unifyAccountsNoData: "Este usuario no tiene datos de este tipo",
    unifyAccountsSuccess: "Unificación finalizada",
    unifyAccountsText: "Las dos cuentas se han unificado, ya puedes seguir navegando",
    unifyAccountsRedirectWarn: "Tienes que indicar la pantalla a la que hay que redirigir",
    unifyAccountIncorrectId: "El identificador de usuario proporcionado no corresponde con ninguna cuenta",
    unifyAccountsMobileNoDoc: "Sin documento",
    unifyAccountsMobileuserData: "Escoge el usuario cuyos datos quieras conservar",
    unifyAccountsMobileuserDocument: "Escoge qué documento quieres conservar",
    unifyAccountsMobileuserEmail: "Selecciona los emails de {user2} que quieras incorporar",
    unifyAccountsMobileuserPhone: "Selecciona los teléfonos de {user2} que quieras incorporar",
    unifyAccountsMobileuserAddress: "Selecciona las direcciones de {user2} que quieras incorporar",
    unifyAccountsMobileuserEnterprise: "Estas son todas las empresas que tendrás",
    unifyAccountsMobileuserSubscription: "Estás son todas las aplicaciones a las que estarás suscrito",
    unifyAccountsMobileuserInfo: "Aquí aportamos información útil de cada usuario",
    unifyAccountsMobileUserInfoLogin: "Último login: ",
    unifyAccountsUserInfoLogin: "Último login",
  
    //Mobile unifyAccouts

    userName: "Nombre",
    userSurname1: "Apellido 1",
    userSurname2: "Apellido 2",
    userbirthDate: "Fecha de nacimiento",
    userNacionality: "Nacionalidad",
    userDocument: "NIF",
    userGender: "Sexo",
    userLanguage: "Idioma",
    stepPage: "Paso {stepPage} de {totalSteps}",
    userInfoMobile: "Información útil",
    userDataMobile: "Datos de usuario",
    emailsMobile: "Emails",
    phonesMobile: "Teléfonos",
    documentsMobile: "Documentos",
    addressMobile: "Direcciones",
    servicesMobile: "Suscripciones",
    enterpriseMobile: "Empresas",
    nextStep: "Siguiente",
    previousStep: "Anterior",
    saveMobile: "Guardar",
  
    //UploadDocumentModal

    uploadDocumentModalContent:
      "Please remember that if there is already a document of the same type in the verification process, that will be lost.",
    uploadDocumentModalTitle: "You are going to upload a new document",
    uploadDocumentFormatUnsupported: "Document format not supported.",
    uploadDocumentSuccess: "Document uploaded correctly",
    uploadDocumentError: "There was an error when uploading the file",
  
    // AtcPage - Stepper

    atcSearchUser: "Search for user",
    atcSelectAccount: "Select/Create account",
    atcUserVerify: "Verify user",
    atcUserData: "User information",
    atcIdentifierField:
      "Enter: DNI, Passport number, Phone number or Email address",
    atcOtpText: "To safeguard your identity, we will send you a code:",
    atcSendEmailButton: "SEND EMAIL",
    atcResendEmailButton: "RESEND EMAIL",
    atcSendSmsButton: "SEND SMS",
    atcResendSmsButton: "RESEND SMS",
    atcRegisterPagePrefix: "Prefix",
    atcRegisterPagePhone: "Telephone number",
    atcRegisterPageEmail: "Email",
    atcRegisterPageDniNie: "ID, Passport no.",
    atcRegisterPageSendOTP: "Enter the code sent",
    atcRegisterPageSendConsentSms: "SEND T&C SMS",
    atcRegisterPageResendConsentSms: "RESEND T&C SMS",
    atcCheckOtpCode: "CHECK CODE",
    atcAccept: "ACCEPT",
    atcClose: "CLOSE",
    atcCancel: "CANCEL",
    atcCreate: "CREATE",
    atcModify: "MODIFY",
    atcVerifyDocumentIdentifier: "VERIFY DNI/PASSPORT",
    atcPhoneError:
      "The telephone number is required, please enter a valid format",
    atcIdentifierDocumentError:
      "The DNI/Passport no. is required, please enter a valid format",
    atcEmailError: "The email address is required, please enter a valid format",
    atcNewAccount: "Create new account",
    atcRadioSelectAccount: 'Select your account or the option "New Account"',
    atcModalTitle: "Verified details",
    atcModalSubtitle: "Please select your account",
    atcModalEmailTitle: "Email addresses:",
    atcModalPhoneTitle: "Phone numbers:",
  
    //DialogMissingData

    dialogMissingDataText: "Hay acciones requeridas para el correcto funcionamiento de la aplicación:",
    dialogMissingDataTextTyC: "Para ello antes debes aceptar la privacidad y los términos y condiciones",
    dialogMissingDataTextDocument: "Necesitas verificar tu documento identificativo lo que implica que no puedes continuar a partir de aquí. Para poder verificarlo te recomendamos que te dirijas a tu oficina de correos más cercana.",
    dialogMissingDataTextNoHolder: "La empresa no tiene los datos obligatorios necesarios, ponte en contacto con el titular para poder continuar.",
    dialogMissingDataunsuscribedEnterprise: "Es necesario suscribirse a la aplicación",
    dialogMissingDatapendingEnterprisePrivacy: "Para ello antes debes aceptar la privacidad y los términos y condiciones",
    dialogMissingDataincompleteUserData: "Rellenar datos de usuario obligatorios",
    dialogMissingDataincompleteEnterpriseData: "Rellenar datos de empresa obligatorios",
    dialogMissingDatapendingEnterprise: "Crear una empresa",
    dialogMissingDataverifyEmail: "Añadir un email verificado",
    dialogMissingDataverifyPhone: "Añadir un teléfono verificado",
    dialogMissingDataverifyAddress: "Añadir una dirección verificada",
    dialogMissingUserDataText:
      "The user information required for the service to function is not correct or incomplete. Please update your information.",
    dialogMissingEnterpriseDataText:
      "A company must be created for this service to work properly. Please create your company.",
  
    // Redirect Page
  
    redirectPageMissingParams: "Faltan parámetros",
    redirectPagePageNotFound: "La página no existe",
  
    // EnterpriseOauthLogin Page
  
    enterpriseOauthLoginPageError: "Faltan parámetros",
    redirectPageIdEnterpriseNotFound: "Se necesita el Id de empresa para acceder a esa página",
    redirectPageNotUserEnterprise: "La empresa indicada no pertenece al usuario",
  
    // ChooseEnterprise Page
  
    chooseEnterpriseNoSubsTitle: "Empresa no suscrita",
    chooseEnterpriseNoSubsText: "La empresa no está suscrita a la aplicación, ponte en contacto con el titular para que la suscriba y poder continuar",
    chooseEnterpriseNoPrivTitle: "Privacidad sin firmar",
    chooseEnterpriseNoPrivText: "La empresa no tiene firmada la privacidad, ponte en contacto con el titular para que la firme y poder continuar",
    chooseEnterpriseNoDataTitle: "Datos requeridos",
    chooseEnterpriseNoDataText: "La empresa no tiene todos los datos necesarios, ponte en contacto con el titular para que los rellene y poder continuar",
    
    // ResetPass Warn

    expiredPassTitle: "Contraseña caducada",
    expiredPassText: "La contraseña ha caducado, por favor, crea una nueva",

    //UserVerifications2FA
  verifications2FATitle: "Configura tu doble factor de autenticación",
  verifications2FAImageText: "Aquí puedes gestionar tus servicios con doble factor de autenticación. Activa o desactiva el doble factor de autenticación para cada uno de los servicios que tengas contratados teniendo en cuenta que hay servicios que no podrás desactivar porque lo exigen por normativa.",
  verifications2FAConfigText: "Servicios contratados",
  verifications2FASetPhone: "Configura tu teléfono",
  verifications2FASetEmailTitle: "Confirma tu identidad",
  verifications2FASetEmailText: "Mandaremos un código de verificación a tu email",
  verifications2FASetEmailConfText: "Mandaremos un código de verificación a tu email para confirmar tu identidad",
  verifications2FASetEmailOtp: "Código de verificación",
  verifications2FASetPhoneTitle: "Configura tu teléfono",
  verifications2FASetPhoneText: "Mandaremos un código de verificación a tu teléfono",
  verifications2FASetPhoneOtp: "Código de verificación",
  verifications2FASetPhonConfirmation: "Ya tienes configurado un teléfono para el doble factor de autenticación. Si estabas intentando hacer login en un servicio, por favor, vuelve a intentarlo.",
  verifications2FAConfigSuccess: "Tu configuración para el doble factor de autenticación se ha actualizado correctamente",

  // Login2FA

  login2FASetPhoneTitle: "Configura tu teléfono",
  login2FASetPhoneText: "Esta aplicación requiere un doble factor de autenticación y necesitas configurar tu teléfono. Para ello accede a la configuración de tu cuenta pulsando en 'Aceptar'.",
  login2FASendPhoneTitle: "Segundo factor de autenticación",
  login2FASendPhoneText: "Esta aplicación requiere un segundo factor de autenticación, introduce el código de verificación que enviaremos a tu teléfono.",
  login2FASetPhoneOtp: "Código de verificación",
  login2FASetPhoneError: "No se ha podido validar el teléfono. Por favor, inténtalo de nuevo.",

  // Oauth - ACM
  loginACMTitle: "Inicio de sesión erróneo",
  loginACMText: "Ha habido un problema con los datos aportados, por favor inténtalo de nuevo",
  generalACMTitle: "Atención",
  generalACMText: "Para poder acceder a la aplicación debes volver a iniciar sesión",

  // GeneralWarning
  generalWarningPassText: "An error occurred while changing the password. If you received the validation code you may have entered it incorrectly, or perhaps it could be that the {dataType} you entered is incorrect, in which case make sure you entered it correctly.",
  generalWarningRegistryText: "There was an error creating the account. If you have received the validation code, perhaps you have entered it incorrectly, or perhaps it could be that the email account you have indicated is already registered in CorreosID, in which case you should recover the password.",
  generalWarningVerifyText: "An error has occurred when verifying the {dataType}. If you have received the validation code, perhaps you have entered it incorrectly, or perhaps it could be that the {dataType} that you have indicated is already verified in CorreosID.",
  };
  