import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Dialog,
  Grid,
  TextField,
} from "@material-ui/core";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { CommonStyles } from "../../commons/CommonStyles";
import { DialogStyles } from "../CommonComponents/Dialogs/DialogStyles";
import { UserVerificationsStyles } from "../UserVerifications/UserVerificationsStyles";
import { checkIsNullUndefined, validatePhoneNumber } from "../../commons/Utils";
import {
  PostVerificationSendEmail,
  PostVerificationSendPhone,
  PostVerificationDataEmail,
  PostVerificationDataPhone,
} from "../../services/ReasonService";
import { AttributeCodes, Attributes } from "../../commons/Attributes";
import { useTranslation } from "react-i18next";
import { GeneralWarning } from "../CommonComponents/GeneralWarning/GeneralWarning";
import { generalWarning } from "../../commons/EnumsGeneral";
import { PrefixPhone } from "../PrefixPhone/PrefixPhone";
import { EnumCountries } from "../../commons/EnumCountries";
import { userEmail, userPhone } from "../../commons/ConstantObjects";

export function DialogVerifications(props) {
  const {
    open,
    setOpen,
    verificable,
    type,
    finishOperation,
    getVerificableName,
    operationType,
    masterCountryList,
    addVerificable,
  } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const classes = UserVerificationsStyles();
  const validator = require("react-email-validator");
  const { t, i18n } = useTranslation();

  const [value, setValue] = useState("");
  const [valueError, setValueError] = useState(false);
  const [valueErrorText, setValueErrorText] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSended, setOtpSended] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otpErrorText, setOtpErrorText] = useState(false);
  const [circularProgress, setCircularProgress] = useState(false);
  const [country, setCountry] = useState({
    code: EnumCountries.ESP,
    id: 67,
    phonePrefix: 34,
  });
  const [isAddPhone, setIsAddPhone] = useState();
  const [openWarnDialog, setOpenWarnDialog] = useState(false);

  useEffect(() => {
    let auxValue = "";
    if (!checkIsNullUndefined(verificable) && operationType === "ver") {
      auxValue = getVerificableName(verificable, type);
    };
    let auxIsAddPhone = operationType === "add" && type === AttributeCodes.Phone;
    setIsAddPhone(auxIsAddPhone);
    setValue(auxValue);
  }, [verificable, operationType, type, getVerificableName]);

  useEffect(() => {
    if (operationType == "add") {
      handleErrors();
    };
  }, [value, country]);

  const handleCancel = () => {
    setOtp("");
    setOtpSended(false);
    setOtpError(false);
    setOtpErrorText("");
    setCircularProgress(false);
    setOpen(false);
  };

  const getValue = () => {
    let auxValue = value;
    if (isAddPhone) {
      auxValue = country.phonePrefix + "-" + auxValue;
    };
    return auxValue;
  };

  const handleSend = () => {
    setCircularProgress(true);
    let auxCall = type === AttributeCodes.Email ? PostVerificationSendEmail : PostVerificationSendPhone;
    auxCall(getValue()).then((response) => {
      if (!checkIsNullUndefined(response) && !response.Status) {
        setOtpSended(true);
      } else {
        finishOperation("error", response);
      };
      setCircularProgress(false);
    });
  };

  const handleConfirm = () => {
    setCircularProgress(true);
    let auxCallVerificate = type === AttributeCodes.Email ? PostVerificationDataEmail : PostVerificationDataPhone;
    let auxText = type === AttributeCodes.Email ? t("verificationsAddEmail") : t("verificationsVerifyPhone");
    auxCallVerificate(getValue(), otp).then(response => handleResponse(response, auxText));
  };

  const handleResponse = (response, successText) => {
    if (!checkIsNullUndefined(response) && !response.Status) {
      finishOperation("success", successText);
      let auxVerificable = operationType == "add" ? createVerificable() : verificable;
      addVerificable(auxVerificable, type, operationType);
      handleCancel();
    } else {
      setOpenWarnDialog(true);
    };
    setCircularProgress(false);
  };

  const createVerificable = () => {
    let auxVerificable = type === AttributeCodes.Email ? userEmail : userPhone;
    auxVerificable[Attributes[type].attributeName] = value;
    if (isAddPhone) {
      auxVerificable.idCountry = country.id;
    };
    return auxVerificable;
  };

  const handleChangeOtp = (value) => {
    let auxiliarError = false;
    let auxiliarHelper = "";
    if (checkIsNullUndefined(value)) {
      auxiliarError = true;
      auxiliarHelper = t("required");
    };
    setOtpError(auxiliarError);
    setOtpErrorText(auxiliarHelper);
    setOtp(value);
  };

  const handleSelectorChange = (value) => {
    setCountry(value);
  };

  const handleChangeData = (value) => {
    const re = type === AttributeCodes.Email ? /^.{0,50}$/ : /^[0-9\b]{0,15}$/;
    if (checkIsNullUndefined(value) || re.test(value)) {
      setValue(value);
    };
  };

  const handleErrors = () => {
    let auxError = { error: false, errorText: "" };
    if (type === AttributeCodes.Email) {
      let auxEmailOk = checkIsNullUndefined(value) || validator.validate(value);
      if (!auxEmailOk) {
        auxError.error = true;
        auxError.errorText = t("registerPageMailError");
      };
    } else {
      auxError = validatePhoneNumber(value, country, t);
    };
    setValueError(auxError.error);
    setValueErrorText(auxError.errorText);
  };

  const getTitle = () => {
    if (operationType === "ver") {
      return type === AttributeCodes.Email ? t("verificationsSendCodeEmail") : t("verificationsSendCodePhone");
    } else {
      return type === AttributeCodes.Email ? t("verificationsAddVerifEmail") : t("verificationsAddVerifPhone");
    };
  };

  return (
    <>
      <GeneralWarning open={openWarnDialog} setOpen={setOpenWarnDialog} isEmail={true} warnType={generalWarning.verify} />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => handleCancel()}
        PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
      >
        <Container className={commonClasses.dialogTitle}>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h4" className={commonClasses.dialogTitleText}>
                {getTitle()}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={commonClasses.dialogContainer}>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} >
              <Typography variant="h6" className={commonClasses.dialogTitleText} >
                {type === AttributeCodes.Email ? t("verifications2FASetEmailText") : t("verifications2FASetPhoneText")}
              </Typography>
            </Grid>
            {isAddPhone ? (
              <Grid item xs={4}>
                <PrefixPhone handleSelectorChange={handleSelectorChange} masterCountryList={masterCountryList} />
              </Grid>
            ) : (null)}
            <Grid item xs={isAddPhone ? 5 : 9}>
              <TextField
                color="secondary"
                value={value}
                variant="filled"
                fullWidth
                label={type === AttributeCodes.Email ? t("UserEmail") : t("PersonaTelfMovil")}
                type="text"
                disabled={operationType == "ver"}
                className={commonClasses.inputsStyle}
                onChange={(e) => handleChangeData(e.target.value)}
                error={valueError}
                helperText={valueErrorText}
              />
            </Grid>
            <Grid item xs={3} className={classes.buttonAdd}>
              <CorreosButton
                variant={otpSended ? "outlined" : "contained"}
                color={otpSended ? "secondary" : "primary"}
                onClick={() => handleSend()}
                disabled={circularProgress || (operationType == "add" && (checkIsNullUndefined(value) || valueError))}
                style={{ width: "100%" }}
              >
                {otpSended ? t("reSend") : t("send")}
              </CorreosButton>
            </Grid>
            {otpSended ? (
              <Grid item xs={12}>
                <TextField
                  color="secondary"
                  value={otp}
                  onChange={(e) => handleChangeOtp(e.target.value)}
                  variant="outlined"
                  fullWidth
                  autoFocus
                  label={t("registerPageOtp")}
                  type="text"
                  id="Otp"
                  error={otpError}
                  helperText={otpErrorText}
                  required
                  className={commonClasses.inputsStyle}
                />
              </Grid>
            ) : null}
            <Grid
              item
              container
              xs={12}
              className={commonClasses.dialogButtonContainer}
            >
              <Grid item md={6} xs={12}>
                <CorreosButton
                  onClick={handleCancel}
                  className={commonClasses.dialogButtonLeft}
                >
                  {t("cancel")}
                </CorreosButton>
              </Grid>
              <Grid item md={6} xs={12}>
                <CorreosButton
                  variant="contained"
                  color="primary"
                  className={commonClasses.dialogButtonRight}
                  disabled={checkIsNullUndefined(otp)}
                  onClick={() => handleConfirm()}
                  circularProgress={circularProgress}
                >
                  {t("verificationsVerify")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Dialog >
    </>
  );
};
