import React, { useEffect, useState } from "react";
import { CorreosIdStepper } from "../../components/Stepper/Stepper";
import { IdentityVerify } from "../../components/IdentityVerify/IdentityVerify";
import { RegisterData } from "../../components/RegisterData/RegisterData";
import { EnumPages } from "../../commons/EnumPages";
import { BackgroundLogin } from "../../components/Templates/BackgroundLogin";
import { Alerts } from "../../components/Alerts/Alerts";
import { checkIsNullUndefined } from "../../commons/Utils";
import { ChooseEnterprise } from "../../components/ChooseEnterprise/ChooseEnterprise";
import { getSessionItem } from "../../services/Commons";
import { sessionValues } from "../../commons/EnumsGeneral";

export function RegisterPage(props) {
  const { personType } = props;
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [isPF, setIsPF] = useState(true);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [enterpriseMandatoryData, setEnterpriseMandatoryData] = useState({ pendingData: [], enterpriseMandatoryData: {} });
  const [allowSignup, setAllowSignup] = useState();
  const [invitationPath, setInvitationPath] = useState(false);

  useEffect(() => {
    let signupAllowed = getSessionItem(sessionValues.allowSignup);
    setAllowSignup(checkIsNullUndefined(signupAllowed) ? "true" : signupAllowed);
  }, []);

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  const _changeStep = (step) => {
    setStep(step);
    setOpen(step == 1);
  };

  const _saveData = (data) => {
    setData(data);
  };

  const _setIsPF = (flag) => {
    setIsPF(flag);
    sessionStorage.setItem("isPF", flag);
  };

  return (
    <>
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      {allowSignup === "true" ? (
        <CorreosIdStepper
          step={0}
          page={EnumPages.Registry}
          isPF={isPF}
        />
      ) : null}
      {invitationPath != true ? (
        <BackgroundLogin size="md" registry={true} step={step} finishOperation={finishOperation}>
          {allowSignup === "true" ? (
            <>
              <RegisterData
                changeStep={_changeStep}
                saveData={_saveData}
                setIsPF={_setIsPF}
                setAlert={setAlert}
                setEnterpriseMandatoryData={setEnterpriseMandatoryData}
                enterpriseMandatoryData={enterpriseMandatoryData}
                personType={personType}
              />
              <IdentityVerify
                changeStep={_changeStep}
                data={data}
                isPF={isPF}
                enterpriseMandatoryData={enterpriseMandatoryData}
                setInvitationPath={setInvitationPath}
                open={open}
                finishOperation={finishOperation}
              />
            </>

          ) : null}
        </BackgroundLogin>
      ) : (
        <ChooseEnterprise />
      )}
    </>
  );
}
