import React, { useEffect, useState } from "react";
import {
    Grid,
    Container,
    Paper,
    CircularProgress,
    Typography,
    Divider,
    Dialog,
    Button
} from "@material-ui/core";
import {
    checkIsNullUndefined,
    getErrorMessage,
} from "../../commons/Utils";
import {
    obtainAttribute,
    isResultOk,
    showAlert,
} from "../../commons/FormManager";
import { CommonStyles } from "../../commons/CommonStyles";
import { CorreosButton } from "../../components/CorreosButton/CorreosButton";
import { GetUtilitiesCorreosIdConfigApplication, PostUtilitiesCorreosIdUnifyAccounts, } from "../../services/UtilitiesCallsService";
import { GetMasterCountry, GetMasterTypeUserDocument, GetMasterLanguage, GetMasterGenders, } from "../../services/MasterCallsService";
import { Attributes } from "../../commons/Attributes";
import { useTranslation } from "react-i18next";
import history from "../../services/history";
import {
    getApplicationOid, getSecureItem, removeSecureItem,
} from "../../services/Commons";
import { secureStorage } from "../../commons/SecureStorage";
import { BasicDialog } from "../CommonComponents/BasicDialog";
import { UnifyAccountsStyles } from "./UnifyAccountsStyles";
import { getScreenWidth } from "../../commons/Utils";
import { preparePage } from "./Components/PreparePageFunctions";
import { handleStepAux, mountUserDataTextAux, mountWebFormsAux, mountWebTitlesAux } from "./Components/MountForms";
import { secureValues } from "../../commons/EnumsGeneral";

export function UnifyAccounts(props) {
    const { finishOperation } = props;
    const commonClasses = CommonStyles();
    const classes = UnifyAccountsStyles();
    const classesObject = { commonClasses, classes };
    const { t, i18n } = useTranslation();

    const user1 = getSecureItem(secureValues.user);
    const user2 = getSecureItem(secureValues.copyUser);

    const [userOriginalData, setUserOriginalData] = useState({});
    const [copyOriginalData, setCopyOriginalData] = useState({});
    const [userDataForm, setUserDataForm] = useState([]);
    const [copyDataForm, setCopyDataForm] = useState([]);
    const [userEmails, setUserEmails] = useState([]);
    const [copyEmails, setCopyEmails] = useState([]);
    const [userPhones, setUserPhones] = useState([]);
    const [copyPhones, setCopyPhones] = useState([]);
    const [userAddresses, setUserAddresses] = useState([]);
    const [copyAddresses, setCopyAddresses] = useState([]);
    const [userEnterprises, setUserEnterprises] = useState([]);
    const [copyEnterprises, setCopyEnterprises] = useState([]);
    const [userServices, setUserServices] = useState([]);
    const [copyServices, setCopyServices] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [copyInfo, setCopyInfo] = useState([]);
    const [allOk, setAllOk] = useState(false);
    const [pageCircularProgress, setPageCircularProgress] = useState(false);
    const [buttonCircularProgress, setButtonCircularProgress] = useState(false);
    const [filtered, setFiltered] = useState(false);
    const [openUnifyUsersDialog, setOpenUnifyUsersDialog] = useState(false);
    const [isMovilTypeScreen, setIsMovilTypeScreen] = useState(false);
    const [nameSelectedMobile, setNameSelectedMobile] = useState(user1);
    const [openUserDataDialog, setOpenUserDataDialog] = useState(false);
    const [phoneSteps, setPhoneSteps] = useState([]);
    const [stepPage, setStepPage] = useState(0);
    const [docTypes, setDocTypes] = useState([]);
    const [docSelectedValue, setDocSelectedValue] = useState({});
    const [userToViewData, setUserToViewData] = useState([]);
    const [auxUser1, setAuxUser1] = useState();
    const [auxUser2, setAuxUser2] = useState();
    const [lastLoginCircularProgress, setLastLoginCircularProgress] = useState(true);
    const [selectedUser, setSelectedUser] = useState(user1);

    const matchList = { "userEmail": copyEmails, "userPhone": copyPhones, "userAddress": copyAddresses };
    const userToViewCodeList = ["NOM", "SUR", "SU2", "FNA", "NAC", "GEN", "LAN"];
    const userToViewMobileInfoCodeList = ["INF"];
    const auxPhonesteps = ["userDocument", "userEmail", "userPhone", "userAddress", "userEnterprise", "userSubscription"];

    useEffect(() => {
        setPageCircularProgress(true);
        let getMasterCountry = GetMasterCountry();
        let getMasterTypeUserDocument = GetMasterTypeUserDocument();
        let getMasterLanguage = GetMasterLanguage();
        let getMasterGenders = GetMasterGenders();
        let auxApplicationOid = getApplicationOid();
        let getUserConfig = GetUtilitiesCorreosIdConfigApplication(1, auxApplicationOid);
        Promise.all([
            getUserConfig,
            getMasterCountry,
            getMasterTypeUserDocument,
            getMasterLanguage,
            getMasterGenders,
        ]).then((results) => {
            let userConfig = results[0];
            let masterCountry = results[1];
            let masterTypeUserDocument = results[2];
            let masterLanguage = results[3];
            let masterGenders = results[4];
            if (
                isResultOk([
                    userConfig,
                    masterCountry,
                    masterTypeUserDocument,
                    masterLanguage,
                    masterGenders,
                ])
            ) {
                setDocTypes(masterTypeUserDocument);
                let masterData = {
                    masterCountry,
                    masterTypeUserDocument,
                    masterLanguage,
                    masterGenders,
                };
                let settersObject = {
                    setCopyOriginalData,
                    setUserOriginalData,
                    setCopyEmails,
                    setCopyPhones,
                    setCopyAddresses,
                    setUserEmails,
                    setUserPhones,
                    setUserAddresses,
                    setCopyDataForm,
                    setUserDataForm,
                    setCopyEnterprises,
                    setUserEnterprises,
                    setCopyServices,
                    setUserServices,
                    setUserInfo,
                    setCopyInfo,
                    setLastLoginCircularProgress
                };
                let auxInfo = {
                    finishOperation,
                    user2,
                    doTraduction: t,
                    isMovilTypeScreen
                };
                preparePage(masterData, userConfig, settersObject, auxInfo);
            } else {
                //Alerta
                showAlert([
                    userConfig,
                    masterCountry,
                    masterTypeUserDocument,
                    masterLanguage,
                    masterGenders,
                ], finishOperation, t);
            }
        });
    }, []);

    useEffect(() => {
        checkAllOk();
        checkSteps();
    }, [filtered]);

    useEffect(() => {
        if (filtered) {
            checkAllOk();
        } else if (userDataForm.length != 0 && copyDataForm.length != 0) {
            filterEqualData();
            filterEqualServices();
            setPageCircularProgress(false);
        }
    }, [userDataForm, copyDataForm]);

    useEffect(() => {
        if (getScreenWidth() > 440) {
            setIsMovilTypeScreen(false);
        } else {
            setIsMovilTypeScreen(true);
        }
    }, []);

    useEffect(() => {
        if (userInfo.length != 0 && copyInfo.length != 0) {
            setLastLoginCircularProgress(false);
        }
    }, [userInfo, copyInfo]);

    const getUserInfo = () => {
        return {
            user1,
            user2,
            auxUser1,
            auxUser2,
            userDataForm,
            copyDataForm,
            userEmails,
            copyEmails,
            userPhones,
            copyPhones,
            userAddresses,
            copyAddresses,
            userEnterprises,
            copyEnterprises,
            userServices,
            copyServices,
        };
    };

    const getStepsInfo = () => {
        return { phoneSteps, stepPage };
    };

    const filterEqualData = () => {
        let auxEqualCodes = [];
        userDataForm.forEach((auxAttr) => {
            let copyAttr = copyDataForm.find((auxCopyAttr) => auxCopyAttr.codeAttribute == auxAttr.codeAttribute);
            if (auxAttr.value == copyAttr.value) {
                auxEqualCodes.push(auxAttr.codeAttribute);
            }
        })
        setAuxUser1(userDataForm);
        setAuxUser2(copyDataForm);
        let auxUserData = userDataForm.filter((attr) => !auxEqualCodes.includes(attr.codeAttribute));
        let auxCopyData = copyDataForm.filter((attr) => !auxEqualCodes.includes(attr.codeAttribute));
        setUserDataForm(auxUserData);
        setCopyDataForm(auxCopyData);
        setFiltered(true);
    };

    const checkSteps = () => {
        let auxSteps = ["userData"];
        auxPhonesteps.forEach((step) => {
            if (userOriginalData[step]?.length != 0 || copyOriginalData[step]?.length != 0) {
                auxSteps.push(step)
            }
        });
        setPhoneSteps(auxSteps);
    };

    const filterEqualServices = () => {
        let auxCopyServicesCode = [];
        userServices.forEach((service) => {
            let auxService = copyServices.find((serv) => serv.value == service.value);
            if (!checkIsNullUndefined(auxService)) {
                auxCopyServicesCode.push(service.value);
            }
        })
        let auxCopyServices = copyServices.filter((service) => !auxCopyServicesCode.includes(service.value));
        setCopyServices(auxCopyServices);
    };

    const handleInputSelected = (event, attribute) => {
        // event.preventDefault();
        let auxCodeAttribute = attribute?.codeAttribute;
        let auxIsCopy = attribute?.isCopy;
        let auxFirstForm = JSON.parse(JSON.stringify(userDataForm));
        let auxParalelForm = JSON.parse(JSON.stringify(copyDataForm));
        if (auxIsCopy) {
            auxFirstForm = JSON.parse(JSON.stringify(copyDataForm));
            auxParalelForm = JSON.parse(JSON.stringify(userDataForm));
        }
        let auxFirstAttribute = obtainAttribute(auxFirstForm, auxCodeAttribute);
        let auxParalelAttribute = obtainAttribute(auxParalelForm, auxCodeAttribute);
        let auxChecked = auxFirstAttribute.checked;
        auxFirstAttribute.checked = !auxChecked;
        auxParalelAttribute.checked = false;
        if (auxIsCopy) {
            setCopyDataForm(auxFirstForm);
            setUserDataForm(auxParalelForm);
        } else {
            setUserDataForm(auxFirstForm);
            setCopyDataForm(auxParalelForm);
        }
    };

    const handleInfoSelected = (event, infoObject) => {
        // event.preventDefault();
        if (infoObject.isCopy && infoObject.value != user2) {
            let auxObjectsList = infoObject.codeAttribute === "EMA" ? JSON.parse(JSON.stringify(copyEmails)) :
                infoObject.codeAttribute === "MOV" ? JSON.parse(JSON.stringify(copyPhones)) :
                    JSON.parse(JSON.stringify(copyAddresses));
            let auxSetCall = infoObject.codeAttribute === "EMA" ? setCopyEmails : infoObject.codeAttribute === "MOV" ? setCopyPhones : setCopyAddresses;
            let auxObject = auxObjectsList.find((auxObj) => auxObj.id === infoObject.id);
            let auxFlag = auxObject.checked;
            auxObject.checked = !auxFlag;
            auxSetCall(auxObjectsList);
        }
    };

    const checkAllOk = () => {
        let flag = { flag: true };
        userDataForm.forEach((attr) => {
            let auxCopyAttr = obtainAttribute(copyDataForm, attr.codeAttribute);
            if (!checkIsNullUndefined(auxCopyAttr) && !attr.checked && !auxCopyAttr.checked) {
                flag.flag = false;
            }
        });
        setAllOk(flag.flag);
    };

    const mountWebTitles = (auxTitle, auxSubtitle) => {
        return mountWebTitlesAux(auxTitle, auxSubtitle, classesObject);
    };

    const mountWebForms = (title, auxArray, inputType) => {
        return mountWebFormsAux(title, auxArray, inputType, classesObject, { handleInputSelected, handleInfoSelected }, t);
    };

    const mountUserDataText = (code) => {
        return mountUserDataTextAux(code, userToViewData);
    };

    const handleStep = () => {
        let settersObject = { setNameSelectedMobile, setSelectedUser, setUserToViewData, setOpenUserDataDialog };
        let handlers = { handleInputSelected, handleInfoSelected };
        let docSelectedObject = { docSelectedValue, setDocSelectedValue };
        let generalInfo = { nameSelectedMobile, getDocCodes, docTypes, docSelectedObject, doTraduction: t, }
        return handleStepAux(getStepsInfo, getUserInfo, classesObject, settersObject, handlers, generalInfo);
    };

    const handleCancel = () => {
        removeSecureItem(secureValues.copyUser);
        history.goBack();
    };

    const handleSave = () => {
        setButtonCircularProgress(true);
        let auxObject = JSON.parse(JSON.stringify(userOriginalData));
        let docCodes = getDocCodes();
        let multiOptions = ["userEmail", "userPhone", "userAddress"];
        auxObject.userDocument = [];
        copyDataForm.forEach((attr) => {
            let auxData = attr.checked ? copyOriginalData : userOriginalData;
            if (docCodes.includes(attr.codeAttribute)) {
                let auxiliarObject = auxData.userDocument.find((auxObj) => auxObj.id == attr.id);
                if (!checkIsNullUndefined(auxiliarObject)) {
                    auxiliarObject.id = 0;
                    auxObject.userDocument.push(auxiliarObject);
                }
            } else {
                let auxValue = checkIsNullUndefined(auxData[Attributes[attr.codeAttribute].attributeName]) ? null : auxData[Attributes[attr.codeAttribute].attributeName];
                auxObject[Attributes[attr.codeAttribute].attributeName] = auxValue;
            }
        })
        multiOptions.forEach((multiOption) => {
            mountObject(auxObject, multiOption);
        });
        auxObject.userEnterprise = [];
        auxObject.userSubscription = [];
        let sendObject = {
            primaryUserOid: getSecureItem(secureValues.userOid),
            secondaryUserOid: copyOriginalData.secondaryUserOid,
            unifiedUserData: auxObject
        }
        PostUtilitiesCorreosIdUnifyAccounts(sendObject).then((response) => {
            if (response && !response.Status) {
                setOpenUnifyUsersDialog(true);
            } else {
                finishOperation("error", getErrorMessage(response, t));
            }
            setButtonCircularProgress(false);
        })
    }

    const mountObject = (auxObject, multiOption) => {
        let auxList = [];

        matchList[multiOption].forEach((obj) => {
            if (obj.checked) {
                let auxiliarObject = copyOriginalData[multiOption].find((auxObj) => auxObj.id == obj.id);
                auxList.push(auxiliarObject);
            }
        })
        auxObject[multiOption] = auxList;
    }

    const handleChangeStep = (type) => {
        if (type === "next") {
            if (stepPage == phoneSteps.length - 1) {
                handleSave();
            } else {
                setStepPage(stepPage + 1);
            }
        } else {
            if (stepPage == 0) {
                handleCancel();
            } else {
                setStepPage(stepPage - 1);
            }
        }
    }

    const handlePrevButtons = () => {
        let auxText = stepPage == 0 ? t("cancel") : t("previousStep");
        return (
            <CorreosButton
                variant="outlined"
                color="secondary"
                className={commonClasses.leftButtonMobile}
                onClick={() => handleChangeStep("previous")}
                disabled={buttonCircularProgress}
            >
                {auxText}
            </CorreosButton>
        );

    }

    const handleNextButtons = () => {
        let auxText = stepPage == phoneSteps.length - 1 ? t("saveMobile") : t("nextStep");
        return (
            <CorreosButton
                variant="contained"
                color="primary"
                className={commonClasses.rightButtonMobile}
                onClick={() => handleChangeStep("next")}
                circularProgress={buttonCircularProgress}
            >
                {auxText}
            </CorreosButton>
        );
    }

    const getDocCodes = () => {
        let auxDocCodes = [];
        docTypes.map((docObj) => {
            auxDocCodes.push(docObj.code);
        });
        return auxDocCodes;
    };

    const getInfoUser = (auxCode) => {
        let auxInfoList = selectedUser == user1 ? userInfo : copyInfo;
        let auxInfo = obtainAttribute(auxInfoList, auxCode);
        return (
            <Grid item xs={12}><Typography variant="subtitle2"><strong>{auxInfo.nameAttribute + ": "}</strong>{auxInfo.value}</Typography></Grid>
        )
    };

    return (
        <Grid className={commonClasses.unifyContainer}>
            <BasicDialog
                open={openUnifyUsersDialog}
                setOpen={setOpenUnifyUsersDialog}
                title={t("unifyAccountsSuccess")}
                text={t("unifyAccountsText")}
                action={handleCancel}
                closeAction={handleCancel}
            />
            {isMovilTypeScreen ?
                <Container className={commonClasses.registerContainer}>
                    <Dialog
                        fullWidth
                        maxWidth="sm"
                        open={openUserDataDialog}
                        onClose={() => setOpenUserDataDialog(false)}
                    >
                        <Container className={commonClasses.dialogTitle}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h4"
                                        className={commonClasses.dialogTitleText}
                                    >
                                        {t("userDataMobile")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                        <Container className={commonClasses.dialogContainer}>
                            <Grid container item xs={12} spacing={1}>
                                {process.env.REACT_APP_ENVELOP_ACTIVE_LAST_LOGIN == "true" ? (
                                    <>
                                        {lastLoginCircularProgress ? <CircularProgress style={{ margin: "auto" }}></CircularProgress> : (
                                            userToViewMobileInfoCodeList.map((auxCode) => {
                                                return getInfoUser(auxCode);
                                            })
                                        )}
                                    </>
                                ) : null}
                                {userToViewCodeList.map((auxCode) => {
                                    return mountUserDataText(auxCode);
                                })}
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                className={commonClasses.dialogButtonContainer}
                            >
                                <Grid item xs={12}>
                                    <Button
                                        size="small"
                                        onClick={() => setOpenUserDataDialog(false)}
                                        variant="contained"
                                        color="primary"
                                        className={commonClasses.dialogButtonCenter}
                                    >
                                        {t("backButton")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                    </Dialog>
                    {pageCircularProgress ? (
                        <CircularProgress />
                    ) : (
                        <Grid item container>
                            < Paper style={{ margin: "1em" }}>
                                <Grid item xs={12}>
                                    <Typography variant="h4" className={commonClasses.gridTitle} style={{ marginTop: "1.5em" }}>
                                        <strong>{t("unifyAccountsTitle")}</strong>
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12}>
                                    {handleStep()}
                                </Grid>
                                <Grid container item xs={12} style={{ marginBottom: "1em", padding: "0.25em" }}>
                                    <Grid item xs={6}>
                                        {handlePrevButtons()}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {handleNextButtons()}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    )}
                </Container>
                :
                <Container className={commonClasses.registerContainer}>
                    {pageCircularProgress ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Paper>
                                <Grid container >
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        className={commonClasses.formContainer}
                                    >
                                        <Grid item xs={12}>
                                            <Typography variant="h4" className={commonClasses.gridTitle} style={{ marginTop: "0.5em", marginBottom: "0.5em" }}>
                                                {t("unifyAccountsTitle")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {process.env.REACT_APP_ENVELOP_ACTIVE_LAST_LOGIN == "true" ? (
                                        <>
                                            <Divider style={{ width: "100%", marginTop: "0.5em", marginBottom: "0.5em" }}></Divider>
                                            {lastLoginCircularProgress ? <CircularProgress style={{ margin: "auto" }}></CircularProgress> : (
                                                <Grid container item xs={12}>
                                                    {mountWebTitles(t("unifyAccountsPreviousInfoTitle"), t("unifyAccountsPreviousInfo"))}
                                                    {mountWebForms(t("unifyAccountsInfo1").replace("{user1}", user1), userInfo, "info")}
                                                    {mountWebForms(t("unifyAccountsInfo2").replace("{user2}", user2), copyInfo, "info")}
                                                </Grid>
                                            )}
                                        </>
                                    ) : null}
                                    <Divider style={{ width: "100%", marginTop: "0.5em", marginBottom: "0.5em" }}></Divider>
                                    {mountWebTitles(t("unifyAccountsDataTitle"), t("unifyAccountsSubTitle"))}
                                    {mountWebForms(t("unifyAccountsData1").replace("{user1}", user1), userDataForm, "data")}
                                    {mountWebForms(t("unifyAccountsData2").replace("{user2}", user2), copyDataForm, "data")}
                                    <Divider style={{ width: "100%", marginTop: "0.5em", marginBottom: "0.5em" }}></Divider>
                                    {mountWebTitles(t("unifyAccountsMultidataTitle"), t("unifyAccountsMultidata").replace("{user1}", user1).replace("{user2}", user2))}
                                    {mountWebForms(t("unifyAccountsEmails1").replace("{user1}", user1), userEmails, "multi")}
                                    {mountWebForms(t("unifyAccountsEmails2").replace("{user2}", user2), copyEmails, "multi")}
                                    <Divider style={{ width: "100%", marginTop: "0.5em", marginBottom: "0.5em" }}></Divider>
                                    {mountWebForms(t("unifyAccountsPhones1").replace("{user1}", user1), userPhones, "multi")}
                                    {mountWebForms(t("unifyAccountsPhones2").replace("{user2}", user2), copyPhones, "multi")}
                                    <Divider style={{ width: "100%", marginTop: "0.5em", marginBottom: "0.5em" }}></Divider>
                                    {mountWebForms(t("unifyAccountsAddress1").replace("{user1}", user1), userAddresses, "multi")}
                                    {mountWebForms(t("unifyAccountsAddress2").replace("{user2}", user2), copyAddresses, "multi")}
                                    <Divider style={{ width: "100%", marginTop: "0.5em", marginBottom: "0.5em" }}></Divider>
                                    {mountWebTitles(t("unifyAccountsEnterpriseTitle"), t("unifyAccountsEnterprise").replace("{user1}", user1).replace("{user2}", user2))}
                                    {mountWebForms(t("unifyAccountsEnteprise1").replace("{user1}", user1), userEnterprises, "enter")}
                                    {mountWebForms(t("unifyAccountsEnteprise2").replace("{user2}", user2), copyEnterprises, "enter")}
                                    <Divider style={{ width: "100%", marginTop: "0.5em", marginBottom: "0.5em" }}></Divider>
                                    {mountWebTitles(t("unifyAccountsServiceTitle"), t("unifyAccountsService").replace("{user1}", user1).replace("{user2}", user2))}
                                    {mountWebForms(t("unifyAccountsService1").replace("{user1}", user1), userServices, "service")}
                                    {mountWebForms(t("unifyAccountsService2").replace("{user2}", user2), copyServices, "service")}
                                </Grid>
                            </Paper>
                            <Grid
                                container
                                item
                                xs={12}
                                className={commonClasses.mainButtonContainer}
                            >
                                <Grid item sm={6} xs={12}>
                                    <CorreosButton
                                        onClick={handleCancel}
                                        className={commonClasses.leftButton}
                                    >
                                        {t("cancel")}
                                    </CorreosButton>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CorreosButton
                                        variant="contained"
                                        color="primary"
                                        className={commonClasses.rightButton}
                                        disabled={!allOk}
                                        onClick={handleSave}
                                        circularProgress={buttonCircularProgress}
                                    >
                                        {t("save")}
                                    </CorreosButton>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Container>
            }
        </Grid >
    );
}
