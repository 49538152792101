import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Container,
  Dialog,
  Grid,
  TextField,
  IconButton,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Popper,
  Paper,
} from "@material-ui/core";
import { CorreosButton } from "../../../components/CorreosButton/CorreosButton";
import { CheckCircle, CheckCircleOutline } from "@material-ui/icons";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import DeleteIcon from "@material-ui/icons/Delete";
import { CommonStyles } from "../../../commons/CommonStyles";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { useTranslation } from "react-i18next";
import {
  DeleteVerificationUserPhone,
  PostVerificationDataPhone,
  PostVerificationSendPhone,
} from "../../../services/ReasonService";
import {
  checkIsNullUndefined,
  getErrorMessage,
  useOutsideAlerter,
  validatePhone,
} from "../../../commons/Utils";
import { PrefixPhone } from "../../PrefixPhone/PrefixPhone";
import { EnumPages } from "../../../commons/EnumPages";
import history from "../../../services/history";
import { RequiredDataStyles } from "../RequiredDataStyles";
import { EnumFirstSelectOption, generalWarning, secureValues } from "../../../commons/EnumsGeneral";
import { setSecureItem } from "../../../services/Commons";
import { GeneralWarning } from "../../CommonComponents/GeneralWarning/GeneralWarning";


export function DialogPhoneActions(props) {
  const {
    actualValue,
    phonesList,
    mandatory,
    mustRegistry,
    handleAddPhone,
    handleVerifyPhone,
    handleRemovePhone,
    countryList,
    finishOperation
  } = props;
  const commonClasses = CommonStyles();
  const dialogClasses = DialogStyles();
  const classes = RequiredDataStyles();
  const { t, i18n } = useTranslation();
  const wrapperRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [operationType, setOperationType] = useState("");
  const [hasValue, setHasValue] = useState(true);
  const [phone, setPhone] = useState({ phone: "", prefixPhone: "" });
  const [country, setCountry] = useState({});
  const [otp, setOtp] = useState("");
  const [sendedOtp, setSendedOtp] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorText, setPhoneErrorText] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otpErrorText, setOtpErrorText] = useState("");
  const [toVerificate, setToVerificate] = useState(false);
  const [verificable, setVerificable] = useState(false);
  const [deletable, setDeletable] = useState(false);
  const [sendCircularProgress, setSendCircularProgress] = useState(false);
  const [actionCircularProgress, setActionCircularProgress] = useState(false);
  const [allOk, setAllOk] = useState(false);
  const [first, setFirst] = useState(true);
  const [openWarnDialog, setOpenWarnDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    let auxHasValue = true;
    let auxVerificable = true;
    let auxDeletable = true;
    let auxPhone = { phone: actualValue, prefixPhone: "" };
    let auxCountry = getCountry(67);
    if (checkIsNullUndefined(actualValue) || actualValue == EnumFirstSelectOption) {
      auxHasValue = false;
      auxVerificable = false;
      auxPhone.phone = "";
    } else {
      let auxPhoneObj = phonesList.find((phone) => phone.phone == actualValue);
      if (!checkIsNullUndefined(auxPhoneObj)) {
        auxCountry = getCountry(auxPhoneObj.idCountry);
        auxDeletable = auxPhoneObj.id != 0;
        auxVerificable = checkIsNullUndefined(auxPhoneObj.idConTypeVerification) || auxPhoneObj.idConTypeVerification < 5;
      }
    }
    auxPhone.prefixPhone = auxCountry.phonePrefix;
    setHasValue(auxHasValue);
    setVerificable(auxVerificable);
    setDeletable(auxDeletable);
    setCountry(auxCountry);
    setPhone(auxPhone);
  }, [actualValue, phonesList]);

  useEffect(() => {
    let allOk = true;
    if (
      (operationType == "add" && phoneError) ||
      checkIsNullUndefined(phone.phone)
    ) {
      allOk = false;
    } else if (
      (operationType == "change" || (operationType == "add" && toVerificate)) &&
      (!sendedOtp ||
        otpError ||
        checkIsNullUndefined(phone.phone) ||
        checkIsNullUndefined(otp))
    ) {
      allOk = false;
    }
    setAllOk(allOk);
  }, [operationType, otp, toVerificate, phoneError, otpError, sendedOtp]);

  useEffect(() => {
    if (first) {
      setFirst(false);
    } else {
      handleValidatePhone();
    }
  }, [phone]);

  const getCountry = (countryId) => {
    let auxCountry = countryList.find(
      (auxCountry) => auxCountry.id == countryId
    );
    return checkIsNullUndefined(auxCountry) ? {} : auxCountry;
  };

  const handleOpen = (event, operationType) => {
    let auxPhone = phone;
    clearAll();
    // setFirst(true);
    if (operationType === "delete") {
      setAnchorEl(event.currentTarget);
    }
    if (operationType === "add") {
      auxPhone.phone = "";
      auxPhone.prefixPhone = 34;
    }
    setOperationType(operationType);
    setPhone(auxPhone);
    setOpen(true);
  };

  const handleCancel = () => {
    clearAll();
    setOperationType("");
    clearOtp();
    setOpen(false);
  };

  const clearOtp = () => {
    setOtp("");
    setOtpError(false);
    setOtpErrorText("");
    setSendedOtp(false);
    setSendCircularProgress(false);
  };

  const clearAll = () => {
    setPhoneError(false);
    setPhoneErrorText("");
    setToVerificate(false);
    setAllOk(false);
    setActionCircularProgress(false);
  };

  const handleChangePrefix = (value, isPrepare) => {
    let auxPhone = JSON.parse(JSON.stringify(phone));
    auxPhone.prefixPhone = value.phonePrefix;
    setFirst(isPrepare);
    clearOtp();
    setCountry(value);
    setPhone(auxPhone);
  };

  const handleChangePhone = (value) => {
    const regex = /^[0-9\b]+$/;
    let auxPhone = JSON.parse(JSON.stringify(phone));
    if (
      checkIsNullUndefined(value.target.value) ||
      (regex.test(value.target.value) && value.target.value.length < 11)
    ) {
      auxPhone.phone = value.target.value;
      clearOtp();
      setPhone(auxPhone);
    }
  };

  const handleValidatePhone = () => {
    let auxError = false;
    let auxErrorText = "";
    if (checkIsNullUndefined(phone.phone)) {
      auxError = true;
      auxErrorText = t("required");
    } else if (checkIsNullUndefined(country.id)) {
      auxError = true;
      auxErrorText = t("registerPagePrefixError");
    } else if (phone.phone.length < 5 || !validatePhone(phone.phone, country)) {
      auxError = true;
      auxErrorText = t("registerPagePhoneError");
    }
    setPhoneError(auxError);
    setPhoneErrorText(auxErrorText);
  };

  const handleChangeOtp = (value) => {
    let auxError = false;
    let auxErrorText = "";
    if (checkIsNullUndefined(value.target.value)) {
      auxError = true;
      auxErrorText = t("required");
    }
    setOtpError(auxError);
    setOtpErrorText(auxErrorText);
    setOtp(value.target.value);
  };

  const handleSendOtp = () => {
    setSendCircularProgress(true);
    PostVerificationSendPhone(phone.prefixPhone + "-" + phone.phone).then(
      (response) => {
        if (response && !response.Status) {
          setSendedOtp(true);
        } else {
          finishOperation("error", getErrorMessage(response, t));
        }
        setSendCircularProgress(false);
      }
    );
  };

  const handleConfirmAction = () => {
    setActionCircularProgress(true);
    if (operationType == "add" || operationType == "change") {
      handleRepeatedPhone();
    } else {
      handleDeleteable();
    };
  };

  const handleRepeatedPhone = () => {
    let auxRepeatedPhone = checkRepeatedPhone();
    if (!auxRepeatedPhone) {
      if (operationType == "add" && !toVerificate) {
        handleAddPhone(phone.phone, country.id);
        finishOperation("warning", t("userDataSaveWarning"));
        handleCancel();
      } else {
        handleVerificationDataPhone();
      };
    };
  };

  const handleDeleteable = () => {
    if (deletable) {
      handleDelete();
    } else {
      finishOperation("success", t("userDataDeletePhoneSuccess"));
      handleRemovePhone(phone.phone, country.id);
      handleCancel();
    };
  };

  const handleDelete = () => {
    DeleteVerificationUserPhone(phone.prefixPhone + "-" + phone.phone).then(
      (response) => {
        if (response && !response.Status) {
          finishOperation("success", t("userDataDeletePhoneSuccess"));
          handleRemovePhone(phone.phone, country.id);
          handleCancel();
        } else {
          finishOperation("error", getErrorMessage(response, t));
          setActionCircularProgress(false);
        };
      });
  };

  const handleVerificationDataPhone = () => {
    PostVerificationDataPhone(
      phone.prefixPhone + "-" + phone.phone,
      otp
    ).then((response1) => {
      if (response1 && !response1.Status) {
        manageVerificationAction();
      } else {
        setOpenWarnDialog(true);
        setActionCircularProgress(false);
      };
    });
  };

  const manageVerificationAction = () => {
    if (operationType == "add" && toVerificate) {
      handleAddPhone(phone.phone, country.id, true);
    } else {
      handleVerifyPhone(phone.phone, country.id);
    };
    finishOperation("success", t("userDataVerifyPhoneOk"));
    handleCancel();
  };

  const checkRepeatedPhone = () => {
    let repeated = false;
    let auxPhoneList = phonesList.map((auxPhone) => auxPhone.phone);
    let auxRepeatedPhonesList = auxPhoneList.filter(
      (auxPhone) => auxPhone == phone.phone
    );
    if (operationType === "change") {
      repeated = auxRepeatedPhonesList.length > 1;
    } else {
      repeated = auxRepeatedPhonesList.length > 0;
    };
    if (repeated) {
      setPhoneError(true);
      setPhoneErrorText(t("verificationsPhoneExists"));
      setActionCircularProgress(false);
    };
    return repeated;
  };

  const handleToVerificate = () => {
    let auxToVerificate = !toVerificate;
    if (!auxToVerificate) {
      clearOtp();
    };
    setToVerificate(auxToVerificate);
  };

  const handleGoToUnify = () => {
    setSecureItem(secureValues.copyUser, phone.prefixPhone + "-" + phone.phone);
    let redirect = "/" + EnumPages.UnifyAccounts;
    history.push(redirect);
  };

  const getShowSend = () => {
    return operationType === "change" || (operationType === "add" && toVerificate);
  };

  useOutsideAlerter(wrapperRef, handleCancel);

  return (
    <Grid
      container
      item
      xs={12}
      spacing={1}
      className={classes.actionsComponentContainer}
    >
      {phonesList.length == 0 ? (
        <Grid item xs={12}>
          <Tooltip title={t("add")}>
            <span>
              <CorreosButton
                color="primary"
                variant="contained"
                className={commonClasses.dialogButtonCenter}
                onClick={(event) => handleOpen(event, "add")}
              >
                <AddToPhotosIcon style={{ marginRight: "0.2em" }} />
                {t("phone")}
                {mandatory ? " *" : ""}
              </CorreosButton>
            </span>
          </Tooltip>
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          spacing={1}
          className={classes.iconsContainer}
        >
          <Grid item xs={4} className={classes.iconContainer}>
            <Tooltip title={t("add")}>
              <IconButton
                classes={{ root: classes.iconButton }}
                onClick={(event) => handleOpen(event, "add")}
              >
                <AddToPhotosIcon className={commonClasses.iconActionStyle} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={4} className={classes.iconContainer}>
            <Tooltip title={t("verificationsVerify") + (mustRegistry ? " *" : "")}>
              <IconButton
                classes={{ root: classes.iconButton }}
                onClick={(event) => handleOpen(event, "change")}
                disabled={!hasValue || !verificable}
              >
                {(!verificable || !hasValue) ?
                  <CheckCircle
                    className={
                      !hasValue || !verificable
                        ? commonClasses.iconDisabledStyle
                        : commonClasses.iconActionStyle
                    }
                  /> :
                  <CheckCircleOutline
                    className={
                      !hasValue || !verificable
                        ? commonClasses.iconDisabledStyle
                        : commonClasses.iconActionStyle
                    }
                  />
                }
                {mustRegistry ? " *" : ""}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={4} className={classes.iconContainer}>
            <Tooltip title={t("delete")}>
              <IconButton
                classes={{ root: classes.iconButton }}
                onClick={(event) => handleOpen(event, "delete")}
                disabled={!hasValue}
              >
                <DeleteIcon
                  className={
                    !hasValue
                      ? commonClasses.iconDisabledStyle
                      : commonClasses.iconActionStyle
                  }
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )
      }
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open && operationType !== "delete"}
        onClose={handleCancel}
        PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
      >
        <Container className={commonClasses.dialogTitle}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={commonClasses.dialogTitleText}
              >
                {operationType === "add"
                  ? t("userDataAddPhone")
                  : operationType === "change"
                    ? t("userDataModifyPhone")
                    : ""}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={commonClasses.dialogContainer}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <PrefixPhone
                handleSelectorChange={handleChangePrefix}
                masterCountryList={countryList}
                country={country}
                disabled={operationType === "change"}
              />
            </Grid>
            <Grid item xs={getShowSend() ? 6 : 8}>
              <TextField
                value={phone.phone}
                onChange={(value) => handleChangePhone(value)}
                variant="filled"
                fullWidth
                id="phone"
                label={t("registerPagePhone")}
                name="phone"
                color="secondary"
                error={phoneError}
                helperText={phoneError ? phoneErrorText : ""}
                disabled={operationType === "change"}
                required
                className={commonClasses.inputsStyle}
              />
            </Grid>
            {getShowSend() ? (
              <Grid item xs={2} className={commonClasses.gridIdentity}>
                <CorreosButton
                  variant={sendedOtp ? "text" : "contained"}
                  color={sendedOtp ? "secondary" : "primary"}
                  onClick={handleSendOtp}
                  className={commonClasses.dialogButtonRight}
                  disabled={phoneError || checkIsNullUndefined(phone.phone)}
                  circularProgress={sendCircularProgress}
                >
                  {sendedOtp ? t("reSend") : t("send")}
                </CorreosButton>
              </Grid>
            ) : null}
            {operationType == "add" ? (
              <Grid item xs={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={toVerificate}
                        name="check"
                        onChange={handleToVerificate}
                        className={commonClasses.leftButton}
                      />
                    }
                    label={t("userDataModifyPhone")}
                    style={{ marginBottom: 0 }}
                  />
                </FormGroup>
              </Grid>
            ) : null}
            {sendedOtp ? (
              <Grid item xs={12}>
                <TextField
                  color="secondary"
                  value={otp}
                  onChange={(value) => handleChangeOtp(value)}
                  variant="filled"
                  fullWidth
                  name="top"
                  label={t("lOtp")}
                  type="text"
                  error={otpError}
                  helperText={otpError ? otpErrorText : ""}
                  required
                  className={commonClasses.inputsStyle}
                />
              </Grid>
            ) : null}
            <Grid
              item
              container
              xs={12}
              className={commonClasses.dialogButtonContainer}
            >
              <Grid item md={6} xs={12}>
                <CorreosButton
                  onClick={handleCancel}
                  className={commonClasses.dialogButtonLeft}
                >
                  {t("cancel")}
                </CorreosButton>
              </Grid>
              <Grid item md={6} xs={12}>
                <CorreosButton
                  variant="contained"
                  color="primary"
                  onClick={handleConfirmAction}
                  className={commonClasses.dialogButtonRight}
                  disabled={!allOk}
                  circularProgress={actionCircularProgress}
                >
                  {operationType == "add" ? t("add") : t("verificationsVerify")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
      <Popper
        open={open && operationType === "delete"}
        anchorEl={anchorEl}
        placement={"top"}
        style={{ zIndex: 100 }}
        ref={wrapperRef}
      >
        <Paper>
          <Container>
            <Grid container style={{ paddingTop: "1em", paddingBottom: "1em" }}>
              <Grid item xs={12}>
                <Typography className={commonClasses.popperContentText}>
                  {t("userDataDeletePhone")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  size="small"
                  onClick={handleCancel}
                  className={commonClasses.leftButton}
                >
                  {t("no")}
                </CorreosButton>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleConfirmAction}
                  className={commonClasses.rightButton}
                  circularProgress={actionCircularProgress}
                >
                  {t("yes")}
                </CorreosButton>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Popper>
      <GeneralWarning open={openWarnDialog} setOpen={setOpenWarnDialog} isEmail={false} warnType={generalWarning.verify} />
    </Grid >
  );
}
