import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
import {
  secureStorage,
  SetSecretKey,
  RemoveSecretKey,
} from "../commons/SecureStorage";
import {
  PostInvitationInvitationAccept,
  PostAuthorizeAuthorize,
  PostAuthorizeToken,
  PostCancelToken,
} from "../services/ReasonService";
import {
  PostEnterpriseDataAcceptChangeEnterpriseRoleProposal,
  PostEnterpriseDataCancelOrRejectChangeRoleProposal,
} from "../services/EnterpriseDataCallsService";
import {
  GetUtilitiesCorreosIdRedirectApplication,
  GetUtilitiesCorreosIdApplicationOid,
  GetUtilitiesCorreosIdUserAndEnterprisesStatuses,
  GetUtilitiesCorreosIdGetUrlRedirectOauth,
  GetUtilitiesCorreosIdEnterpriseInvitation,
  GetUtilitiesCorreosIdRedirectInvitation,
} from "../services/UtilitiesCallsService";
import { checkIsNullUndefined, decode64, getErrorMessage } from "../commons/Utils";
import { EnumPages } from "../commons/EnumPages";
import { ActivedLenguages } from "../commons/ActivedLenguages";
import history from "./history.js";
import { errorCodes } from "../commons/ConstantObjects";
import { EnumAllowedRedirectEnterprisePages, EnumAllowedRedirectUserPages, EnumAllowedSSOEnterprisePages, EnumAllowedSSOUserPages, oauthApplications, secureValues, sessionValues } from "../commons/EnumsGeneral";
import { Constants } from "../commons/Constants.js";
import { GetApplicationAuxApplicationData } from "./ApplicationAuxCallsService.js";
import { isResultOk } from "../commons/FormManager.js";

//Funciones set, get y delete de sessionStorage

export const setSessionItem = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const getSessionItem = (key) => {
  return sessionStorage.getItem(key) || null;;
};

export const removeSessionItem = (key) => {
  sessionStorage.removeItem(key);
};

//Funciones get y set del secureStorage

export const setSecureItem = (key, value) => {
  secureStorage.setItem(key, value);
};

export const getSecureItem = (key) => {
  return secureStorage.getItem(key) || null;;
};

export const removeSecureItem = (key) => {
  secureStorage.removeItem(key);
};

// return the token from the session storage
export const getApplicationOid = () => {
  let auxAppOid = getSessionItem(sessionValues.appOid);
  return getReplaceMode() == "true" ? process?.env?.REACT_APP_APP_OID : checkIsNullUndefined(auxAppOid) ? process?.env?.REACT_APP_APP_OID : auxAppOid;
};

export const getReplaceApplicationOid = () => {
  return getSessionItem(sessionValues.appOid);
};

// return the token from the session storage
export const getAPI = () => {
  return process?.env?.REACT_APP_API_URL;
};

export const getOauthApi = () => {
  return process?.env?.REACT_APP_OAUTH_API_URL;
};

// return the token from the session storage
export const getLanguage = () => {
  return sessionStorage.getItem("language") || "es";
};

export const getSecToken = () => {
  return jwt_decode(getSessionItem(sessionValues.idToken));
};

// set the token and user from the session storage
export const setUserSession = async (loginInfo, i18n, appOid) => {
  let auxAppOid = getReplaceMode() == "true" ? getReplaceApplicationOid() : checkIsNullUndefined(appOid) ? getApplicationOid() : appOid;
  setIsRefreshing(false);
  let json = jwt_decode(loginInfo.idToken);
  setLanguage(loginInfo.language, i18n);
  setSessionItem(sessionValues.idToken, loginInfo.idToken);
  setSessionItem(sessionValues.refreshToken, loginInfo.refreshToken);
  setSessionItem(sessionValues.time, Date.now());
  setSessionItem(sessionValues.appOid, auxAppOid);
  setSessionItem(sessionValues.logged, true);
  RemoveSecretKey();
  await SetSecretKey(loginInfo.idToken).then(() => {
    //guardamos los datos cifrados en sesión.  
    setSecureItem(secureValues.user, json.sub);
    setSecureItem(secureValues.userOid, json.oid);
    setSecureItem(secureValues.token, loginInfo);
    return true;
  });
};

// remove user session
export const removeUserSession = async (leaveCookies) => {
  sessionStorage.clear();
  RemoveSecretKey();
  secureStorage.clear();
  if (checkIsNullUndefined(leaveCookies) || !leaveCookies) {
    const cookies = new Cookies();
    cookies.remove(Constants.cookieValue);
  };
  return true;
};

export const getEidFromToken = (loginInfo) => {
  let json = jwt_decode(loginInfo.idToken);
  return json.eid;
};

export const hasPagePermission = (page, idEnterprise, isSSO) => {
  let auxAllowedPages = {};
  if (isSSO) {
    auxAllowedPages = checkIsNullUndefined(idEnterprise) ? EnumAllowedSSOUserPages : EnumAllowedSSOEnterprisePages;
  } else {
    auxAllowedPages = checkIsNullUndefined(idEnterprise) ? EnumAllowedRedirectUserPages : EnumAllowedRedirectEnterprisePages;
  };
  return (page in auxAllowedPages);
};

export const getEnterpriseOidByListId = (id) => {
  let enterprises = getSecureItem(secureValues.enterpriseList);
  return enterprises[id].enterpriseOid;
};

export const getListIdByEnterpriseOid = (oid) => {
  let enterprises = getSecureItem(secureValues.enterpriseList);

  return enterprises.findIndex(function (item) {
    return item.enterpriseOid === oid;
  });
};

export const CheckPassCorreosID = (pass) => {
  const hasNumber = new RegExp("[0-9]");
  const hasUpperChar = new RegExp("[A-Z]");
  const hasLowerChar = new RegExp("[a-z]");
  const hasNumberCharacters = new RegExp(".{8,16}");
  const hasSimbols = new RegExp("[@#$%^&*-_+=[\x93{}|\\:',?/`~\"();.]");

  let okPass = hasNumberCharacters.test(pass);

  if (okPass) {
    let flag = 0;
    if (hasNumber.test(pass)) {
      flag++;
    };
    if (hasUpperChar.test(pass)) {
      flag++;
    };
    if (hasLowerChar.test(pass)) {
      flag++;
    };
    if (hasSimbols.test(pass)) {
      flag++;
    };
    if (flag < 3) {
      okPass = false;
    };
  };
  return okPass;
};

export const setUserCookies = (user, rememberMe, generateCookie) => {
  if (rememberMe) {
    generateCookie(user);
  }
};

const manageAppInfo = (appId, setCircularProgress, t) => {
  let getUtilitiesCorreosIdApplicationOid = GetUtilitiesCorreosIdApplicationOid(appId, process?.env?.REACT_APP_APP_OID);
  let getApplicationAuxApplicationData = GetApplicationAuxApplicationData();
  Promise.all([getUtilitiesCorreosIdApplicationOid, getApplicationAuxApplicationData]).then((results) => {
    let utilitiesCorreosIdApplicationOidResult = results[0];
    let applicationAuxApplicationDataResult = results[1];
    let auxPersonType = 1;
    let auxAppName = t("correosId");
    let auxAllowSignup = true;
    if (isResultOk([utilitiesCorreosIdApplicationOidResult, applicationAuxApplicationDataResult])) {
      setSessionItem(sessionValues.appOid, utilitiesCorreosIdApplicationOidResult);
      auxPersonType = applicationAuxApplicationDataResult.personType.id;
      auxAppName = !checkIsNullUndefined(applicationAuxApplicationDataResult.nameImage.name) ? applicationAuxApplicationDataResult.nameImage.name : t("correosId");
      auxAllowSignup = !checkIsNullUndefined(applicationAuxApplicationDataResult.allowSignup) ? applicationAuxApplicationDataResult.allowSignup : true;
      delete applicationAuxApplicationDataResult.nameImage.name;
      delete applicationAuxApplicationDataResult.nameImage.allowSignup;
      let auxImagesObj = applicationAuxApplicationDataResult.nameImage;
      const imagesNameList = ["imageLogin", "imageRegistry", "imagePassword"];
      imagesNameList.forEach((image) => {
        setSessionItem(sessionValues[image], auxImagesObj[image]);
      });
    };
    setSessionItem(sessionValues.appPersonType, auxPersonType);
    setSessionItem(sessionValues.appName, auxAppName);
    setSessionItem(sessionValues.allowSignup, auxAllowSignup);
    setCircularProgress(false);
  });
};

export const getReplaceMode = () => {
  return sessionStorage.getItem(sessionValues.replaceMode);
};

export const setReplaceMode = (value) => {
  sessionStorage.setItem(sessionValues.replaceMode, value);
};

export const backToLogin = () => {
  PostCancelToken(getSessionItem(sessionValues.idToken)).then(() => {
    closeSessionToLogin();
  });
};

export const closeSessionToLogin = () => {
  let auxOriginalLogin = mountOrigialLogin();
  removeUserSession().then(() => {
    window.location.replace(auxOriginalLogin);
  });
};

const mountOrigialLogin = () => {
  return process?.env?.REACT_APP_WEB_URL + getEntranceUrl(EnumPages.Login);
};

const goToMain = () => {
  setReplaceMode(false);
  let redirect = "/" + EnumPages.Index;
  if(getSessionItem("2FA") == "true"){
    redirect += "/" + EnumPages.UserVerifications;
  };
  let originUrl = getSessionItem(sessionValues.originUrl);
  if (originUrl?.includes("Index")) {
    redirect = originUrl.substring(originUrl.indexOf("Index"));
  };
  history.push(redirect);
  setSessionItem(sessionValues.logged, true);
  removePublicItems();
};

const removePublicItems = () => {
  const auxPublicItems = [sessionValues.attributeInfo, sessionValues.attributes, sessionValues.fieldType, sessionValues.userConfiguration, sessionValues.enterpriseConfiguration, sessionValues.offersAndProms, sessionValues.cidPrivacyAndTyc];
  auxPublicItems.forEach((item) => {
    removeSessionItem(item);
  });
};

export const controlUserMovement = (inside, finishOperation, t, setOpenACM) => {
  // Temporal para ACM *****
  let auxAppCode = getSessionItem(sessionValues.appCode);
  if (oauthApplications.includes(auxAppCode)) {
    setOpenACM(true);
  } else {
    let hasInvitation = checkForInvitations();
    if (hasInvitation) {
      invitationPath(finishOperation, t).then(() => {
        switchUserMovement(inside, finishOperation, t);
      });
    } else {
      switchUserMovement(inside, finishOperation, t);
    };
  };
};

const switchUserMovement = (inside, finishOperation, t) => {
  if (inside) {
    goToMain();
  } else {
    moveToOtherApp(finishOperation, t);
  };
};

export const checkEnterprisePath = (auxEnterpriseId, finishOperation, t) => {
  getAndSetErrorCodes(auxEnterpriseId).then(() => {
    let missData = missedInfo();
    if (missData.length > 0) {
      history.push("/" + EnumPages.PendingData);
    } else {
      controlUserMovement(false, finishOperation, t);
    };
  });
};

export const checkUserPath = (forEnterprise, finishOperation, t, setOpenACM) => {
  let redirect = "/" + EnumPages.Index;
  let appOid = getApplicationOid();
  let doRedirect = true;
  let originUrl = getSessionItem(sessionValues.originUrl);
  getAndSetErrorCodes(null, forEnterprise).then(() => {
    let missData = missedInfo();
    if (missData.length > 0 || forEnterprise) {
      redirect = "/" + EnumPages.PendingData;
    } else {
      if (appOid !== process?.env?.REACT_APP_APP_OID) {
        doRedirect = false;
        controlUserMovement(false, finishOperation, t, setOpenACM);
      } else if (originUrl?.includes("Index")) {
        redirect = originUrl.substring(originUrl.indexOf("Index"));
      };
    };
    if (doRedirect) {
      history.push(redirect);
    };
  });
};

export const getAndSetErrorCodes = async (idEnterprise, forEnterprise) => {
  cleanErrorCodes();
  return await GetUtilitiesCorreosIdUserAndEnterprisesStatuses().then(
    (response) => {
      if (response && !response.Status) {
        if (forEnterprise && response.enterprises.length == 0) {
          setSessionItem(errorCodes[999], true);
        };
        if (!checkIsNullUndefined(idEnterprise)) {
          let auxUser = setErrorCodes(response.user.filter(x => x !== 203 && x !== 206), false);
          let auxEnterprise = setErrorCodes(response.enterprises.find(x => x.idEnterprise == idEnterprise).statuses, true);
          return { auxUser, auxEnterprise };
        } else {
          return setErrorCodes(response.user, false);
        };
      };
    }
  );
};

const cleanErrorCodes = () => {
  let auxSessionValues = [
    errorCodes[999],
    errorCodes[202],
    errorCodes[2020],
    errorCodes[203],
    errorCodes[2030],
    errorCodes[206],
    errorCodes[2060],
    errorCodes[212],
    errorCodes[222],
    errorCodes[232],
    errorCodes[242],
  ];
  auxSessionValues.forEach((errorText) => {
    removeSessionItem(errorText);
  });
};

const moveToOtherApp = (finishOperation, t) => {
  let auxURL = getSessionItem(sessionValues.redirectURI);
  setSessionItem(sessionValues.replaceMode, false);
  if (checkIsNullUndefined(auxURL)) {
    let auxInvRedirect = getSessionItem("invRedirect");
    let auxCall = !checkIsNullUndefined(auxInvRedirect) && auxInvRedirect == "true" ? GetUtilitiesCorreosIdRedirectInvitation : GetUtilitiesCorreosIdRedirectApplication;
    auxCall().then((response) => {
      // Redirección/pasar info por form
      if (response && !response.Status) {
        buildAndSendForm(response);
      } else {
        finishOperation("error", getErrorMessage(response, t));
      };
    });
  } else {
    buildAndSendForm(auxURL);
  };
};

const buildAndSendForm = (redirectURI) => {
  let form = document.createElement("form");
  document.body.appendChild(form);
  form.method = "post";
  form.action = redirectURI;
  let input = document.createElement("input");
  input.type = "hidden";
  input.name = "body";
  let auxToken = getSecureItem(secureValues.token);
  delete auxToken['sessionCookie'];
  auxToken = JSON.stringify(auxToken);
  input.value = auxToken;
  form.appendChild(input);
  form.submit();
  document.body.removeChild(form);
  removeUserSession(true);
};

export const setLanguage = (auxLanguage, i18n) => {
  let language = checkIsNullUndefined(auxLanguage) ? "es" : auxLanguage;
  if (
    checkIsNullUndefined(ActivedLenguages[language.toLowerCase()]) ||
    !ActivedLenguages[language.toLowerCase()]
  ) {
    language = "es";
  };
  sessionStorage.setItem("language", language);
  i18n.changeLanguage(language.toLowerCase());
};

export const setIsRefreshing = (isRefreshing) => {
  return sessionStorage.setItem("isRefreshing", isRefreshing);
};

export const getIsRefreshing = () => {
  let response = false;
  let isRefreshing = sessionStorage.getItem("isRefreshing");
  if (!checkIsNullUndefined(isRefreshing) && isRefreshing === "true") {
    response = true;
  };
  return response;
};

export const sleep = async (ms) => {
  return await new Promise((resolve) => setTimeout(resolve, ms));
};

const setErrorCodes = (auxErrorCodes, isEnterprise) => {
  let errorFactor = isEnterprise ? 10 : 1;
  auxErrorCodes.forEach((errorCode) => {
    let auxCode = errorCode * errorFactor;
    let auxError = "";
    auxError = errorCodes[auxCode];
    sessionStorage.setItem(auxError, true);
  });
  return true;
};

export const missedInfo = () => {
  let auxMissedInfo = [];
  let auxSessionValues = [
    errorCodes[999],
    errorCodes[202],
    errorCodes[2020],
    errorCodes[203],
    errorCodes[2030],
    errorCodes[206],
    errorCodes[2060],
    errorCodes[212],
    errorCodes[222],
    errorCodes[232],
    errorCodes[242],
  ];
  auxSessionValues.forEach((errorText) => {
    let auxErrorState = sessionStorage.getItem(errorText);
    if (auxErrorState == "true") {
      auxMissedInfo.push(errorText);
    }
  });
  return auxMissedInfo;
};

export const checkForInvitations = () => {
  let auxInvitation = sessionStorage.getItem("idInvitation");
  let auxChangeRoleProposalId = sessionStorage.getItem("ChangeRoleProposalId");
  let auxRejectChangeRoleProposalId = sessionStorage.getItem("RejectChangeRoleProposalId");
  return !checkIsNullUndefined(auxInvitation) || !checkIsNullUndefined(auxChangeRoleProposalId) || !checkIsNullUndefined(auxRejectChangeRoleProposalId);
}

export const invitationPath = async (finishOperation, t) => {
  let idInvitation = sessionStorage.getItem("idInvitation");
  let ChangeRoleProposalId = sessionStorage.getItem(
    "ChangeRoleProposalId"
  );
  let RejectChangeRoleProposalId = sessionStorage.getItem(
    "RejectChangeRoleProposalId"
  );
  let auxReturn = { entepriseId: null, enterpriseRol: null, returnInfo: "" };
  let auxInvObj = !checkIsNullUndefined(idInvitation) ? { value: idInvitation, code: "INV" } : !checkIsNullUndefined(ChangeRoleProposalId) ? { value: ChangeRoleProposalId, code: "CHA" } : { value: RejectChangeRoleProposalId, code: "CAN" };
  if (!checkIsNullUndefined(auxInvObj.value)) {
    let invitationObject = {
      INV: { auxCall: PostInvitationInvitationAccept, auxKey: "idInvitation", auxSet: "InvitationAccept", auxSuccess: "InvitationAcceptSuccess" },
      CHA: { auxCall: PostEnterpriseDataAcceptChangeEnterpriseRoleProposal, auxKey: "ChangeRoleProposalId", auxSet: "AcceptChangeEnterpriseRole", auxSuccess: "AcceptChangeEnterpriseRoleSuccess" },
      CAN: { auxCall: PostEnterpriseDataCancelOrRejectChangeRoleProposal, auxKey: "RejectChangeRoleProposalId", auxSet: "CancelOrRejectChangeRole", auxSuccess: "CancelOrRejectChangeRoleSuccess" }
    };
    return await invitationObject[auxInvObj.code].auxCall(auxInvObj.value).then((response) => {
      let flag = true;
      if (response && !response.Status) {
        auxReturn.enterpriseRol = response;
        removeSessionItem(invitationObject[auxInvObj.code].auxKey);
        finishOperation("success", t(invitationObject[auxInvObj.code].auxSuccess));
        return obtainEnterpriseId(auxInvObj.value, finishOperation, t).then((response1) => {
          auxReturn.entepriseId = response1;
          sessionStorage.setItem(invitationObject[auxInvObj.code].auxSet, flag);
          return auxReturn;
        });
      } else {
        finishOperation("error", getErrorMessage(response, t));
        sessionStorage.setItem(invitationObject[auxInvObj.code].auxSet, flag);
        auxReturn.returnInfo = "error";
        return auxReturn;
      }
    });
  } else {
    auxReturn.returnInfo = "noInv";
    return auxReturn;
  };
};

export const obtainEnterpriseId = async (invId, finishOperation, t) => {
  return GetUtilitiesCorreosIdEnterpriseInvitation(invId).then((response) => {
    if (response && !response.Status) {
      return response;
    } else {
      finishOperation("error", getErrorMessage(response, t));
      return "error";
    };
  });
};

export const doLogin = async (user, password, i18n, hasInvitation) => {
  let auxApplicationOid = getApplicationOid();
  return await GetUtilitiesCorreosIdGetUrlRedirectOauth(auxApplicationOid).then((response) => {
    if (response && !response.Status) {
      let redirectUrl = response[0];
      return PostAuthorizeAuthorize(user, password, redirectUrl).then((response1) => {
        if (response1 && !response1.Status) {
          let indice = response1.indexOf("code=");
          let code = response1.substring(indice + 5);
          return PostAuthorizeToken(code, redirectUrl).then((response2) => {
            if (response2 && (!response2.Status || response2.Status == 202 || hasInvitation)) {
              setUserSession(response2, i18n, auxApplicationOid, user).then(() => {
                if (process.env.REACT_APP_ENVELOP_ACTIVE_SESSION_COOKIE === "true") {
                  setSessionCookie(response2.sessionCookie);
                };
                return true;
              });
            } else {
              backToLogin();
              return false;
            };
          });
        } else {
          backToLogin();
          return false;
        };
      });
    } else {
      backToLogin();
      return false;
    };
  });
};

export const setAppEntranceUrl = (setCircularProgress, t) => {
  //Eliminar cuando se tenga la pantalla de redirección???
  setSessionItem(sessionValues.originUrl, window.location.href);
  setSessionItem(sessionValues.entranceUrl, window.location.href);
  //Volvemos a rellenar los datos de la app
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  // Temporal para ACM *****
  let pendingData = urlParams.get("pD");
  pendingData = !checkIsNullUndefined(pendingData) && pendingData == 1 ? true : false;
  setSessionItem(sessionValues.pendingData, pendingData);
  // ***********************
  let appId = urlParams.get("appId");
  let auxAppCode = !checkIsNullUndefined(appId) ? appId : Constants.cidCode;
  if (!settedAppValues(auxAppCode)) {
    setSessionItem(sessionValues.appCode, auxAppCode);
    manageAppInfo(auxAppCode, setCircularProgress, t);
  } else {
    setCircularProgress(false);
  };
  //Recuperamos los datos de la url
  const urlParamsValues = ["method", "state", "idInvitation", "ChangeRoleProposalId", "RejectChangeRoleProposalId", "aC", "pT", "invRedirect"];
  urlParamsValues.forEach((paramValue) => {
    manageUrlValues(urlParams.get(paramValue), paramValue);
  });
};

const settedAppValues = (newAppCode) => {
  let actualAppCode = getSessionItem(sessionValues.appCode);
  return newAppCode == actualAppCode;
};

const manageUrlValues = (value, valueCode) => {
  if (checkIsNullUndefined(value)) {
    sessionStorage.removeItem(valueCode);
  } else {
    sessionStorage.setItem(valueCode, value);
  };
};

export const getEntranceUrl = (page) => {
  let url = page;
  let auxUrl = getSessionItem(sessionValues.entranceUrl);
  if (!checkIsNullUndefined(auxUrl) && auxUrl.indexOf("?") != -1) {
    url += "?" + auxUrl.split("?")[1];
  };
  return url;
};

export const setSessionCookie = (sessionCookie) => {
  const cookies = new Cookies();
  if (!checkIsNullUndefined(sessionCookie)) {
    let auxCookie = decode64(sessionCookie);
    cookies.set(Constants.cookieValue, sessionCookie, {
      path: "/",
      expires: new Date(auxCookie.ExpirationDate),
    });
  };
};

export const goToLoginOauth = () => {
  let auxState = getSessionItem(sessionValues.state);
  let auxOauthApplication = getSessionItem(sessionValues.appCode);
  let auxUrl = process?.env?.REACT_APP_WEB_URL + "login?appId=" + auxOauthApplication + "&state=" + auxState;
  removeUserSession().then(() => {
    if (!checkIsNullUndefined(auxState)) {
      window.location.href = auxUrl;
    };
  });
};