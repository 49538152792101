import React, { useEffect, useState } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import {
    PostAuthorizeToken,
    GetEnterpriseSelect,
    GetEnterpriseUserEnterprises,
} from "../../services/ReasonService";
import {
    GetUtilitiesCorreosIdGetUrlRedirectOauth,
    GetUtilitiesCorreosIdUserAndEnterprisesStatuses,
} from "../../services/UtilitiesCallsService";
import { ChooseEnterpriseStyles } from "./ChooseEnterpriseStyles";
import { DialogEnterprise } from "./Components/DialogEnterprise";
import { BackgroundLogin } from "../../components/Templates/BackgroundLogin";
import { Alerts } from "../../components/Alerts/Alerts";
import {
    backToLogin,
    checkEnterprisePath,
    checkUserPath,
    getApplicationOid,
    getSecToken,
    missedInfo,
    setSessionCookie,
    setSessionItem,
    setUserSession,
} from "../../services/Commons";
import { useTranslation } from "react-i18next";
import { checkIsNullUndefined, getErrorMessage } from "../../commons/Utils";
import { DialogPrivacy } from "../../components/CommonComponents/Dialogs/DialogPrivacy";
import { DialogSubscribe } from "../../components/CommonComponents/Dialogs/DialogSubscribe";
import { invitationPath } from "../../services/Commons";
import { isResultOk, showAlert } from "../../commons/FormManager";
import { BasicDialog } from "../CommonComponents/BasicDialog";
import { GetEnterpriseDataRole } from "../../services/EnterpriseDataCallsService";

export function ChooseEnterprise(props) {
    const { code, authorizationUrl, SSOStatus } = props;
    const classes = ChooseEnterpriseStyles();
    const { t, i18n } = useTranslation();

    const [circularProgress, setCircularProgress] = useState(true);
    const [enterprises, setEnterprises] = useState([]);
    const [enterpriseId, setEnterpriseId] = useState();
    const [openPrivacy, setOpenPrivacy] = useState(false);
    const [openSubscribe, setOpenSubscribe] = useState(false);
    const [openNotTitular, setOpenNotTitular] = useState(false);
    const [notTitularTitle, setNotTitularTitle] = useState("");
    const [notTitularText, setNotTitularText] = useState("");
    const [alert, setAlert] = useState({
        open: false,
        color: "",
        message: "",
    });

    useEffect(() => {
        if (!checkIsNullUndefined(SSOStatus)) {
            prepareSSOPath();
        } else {
            preparePageOptionsAndUser();
        };
    }, []);

    const prepareSSOPath = () => {
        let token = getSecToken();
        GetEnterpriseDataRole(token.eid).then((response) => {
            if (response && !response.Status) {
                setEnterpriseId(token.eid);
                sessionStorage.setItem('enterpriseSelectedId', token.eid);
                handleEntepriseStatus({ Status: SSOStatus == 200 ? null : SSOStatus }, token.eid, response);
            } else {
                finishOperation("error", getErrorMessage(response, t));
            };
        });
    };

    const finishOperation = (color, message) => {
        setAlert({
            open: true,
            color,
            message,
        });
    };

    const preparePageOptionsAndUser = () => {
        invitationPath(finishOperation, t).then((response) => {
            manageInvitationResponse(response);
        });
    };

    const manageInvitationResponse = (invResponse) => {
        if (invResponse.returnInfo == "noInv") {
            prepareEnterpriseUsualLogin();
        } else if (invResponse.returnInfo != "error" && invResponse.entepriseId != "error") {
            manageEnterpriseLogin(invResponse.entepriseId, invResponse.entepriseRol);
        } else {
            backToLogin();
        };
    };

    const prepareEnterpriseUsualLogin = () => {
        missedInfo();
        let getEnterpriseUserEnterprises = GetEnterpriseUserEnterprises();
        let getUtilitiesCorreosIdUserAndEnterprisesStatuses = GetUtilitiesCorreosIdUserAndEnterprisesStatuses();
        Promise.all([getEnterpriseUserEnterprises, getUtilitiesCorreosIdUserAndEnterprisesStatuses]).then((results) => {
            let userEnterprisesResult = results[0];
            let userAndEnterprisesStatuses = results[1];
            let enterprisesWithSubscription = [];
            if (isResultOk([userEnterprisesResult, userAndEnterprisesStatuses])) {
                userEnterprisesResult.forEach(auxEnterprise => {
                    userAndEnterprisesStatuses.enterprises.forEach(auxEnterpriseStatus => {
                        if (auxEnterprise.id === auxEnterpriseStatus.idEnterprise && !auxEnterpriseStatus.statuses.find(z => z === 203)) {
                            enterprisesWithSubscription.push(auxEnterprise);
                        };
                    });
                });
                if (enterprisesWithSubscription.length === 0) {
                    if (userEnterprisesResult.length === 1) {
                        manageEnterpriseLogin(userEnterprisesResult[0].id, userEnterprisesResult[0].idRol);
                    } else if (userEnterprisesResult.length > 1) {
                        setEnterprises(userEnterprisesResult);
                        setCircularProgress(false);
                    } else {
                        goToCreateEnterprise();
                    };
                } else if (enterprisesWithSubscription.length === 1) {
                    manageEnterpriseLogin(enterprisesWithSubscription[0].id, userEnterprisesResult[0].idRol);
                } else {
                    setEnterprises(enterprisesWithSubscription);
                    setCircularProgress(false);
                };
            } else {
                showAlert([userEnterprisesResult, userAndEnterprisesStatuses], finishOperation, t)
            };
        }).catch((error) => {
            finishOperation("error", getErrorMessage(error, t));
            setCircularProgress(false);
        });
    };

    const goToCreateEnterprise = () => {
        if (checkIsNullUndefined(authorizationUrl)) {
            let appOid = getApplicationOid();
            GetUtilitiesCorreosIdGetUrlRedirectOauth(appOid).then((response) => {
                if (response && !response.Status) {
                    doUserLoginForCreateEnterprise(response);
                } else {
                    finishOperation("error", getErrorMessage(response, t));
                }
            })
        } else {
            doUserLoginForCreateEnterprise(authorizationUrl);
        }
    }

    const doUserLoginForCreateEnterprise = (auxAuthorizationUrl) => {
        setCircularProgress(true);
        PostAuthorizeToken(code, auxAuthorizationUrl, null).then((response) => {
            setUserSession(response, i18n, null).then(() => {
                invitationPath(finishOperation, t).then(() => {
                    checkUserPath(true, finishOperation, t);
                })
            });
        })
    }

    const manageEnterpriseLogin = (auxEnterpriseId, auxEntepriseRol) => {
        sessionStorage.setItem('enterpriseSelectedId', auxEnterpriseId);
        let appOid = getApplicationOid();
        setEnterpriseId(auxEnterpriseId);
        setCircularProgress(true);
        GetEnterpriseSelect(auxEnterpriseId).then((response) => {
            if (response) {
                setUserSession(response, i18n, appOid).then(() => {
                    if (process.env.REACT_APP_ENVELOP_ACTIVE_SESSION_COOKIE === "true") {
                        setSessionCookie(response.sessionCookie);
                    };
                    handleEntepriseStatus(response, auxEnterpriseId, auxEntepriseRol);
                });
            } else {
                finishOperation("error", getErrorMessage(response, t));
            };
        });
    };

    const handleEntepriseStatus = (response, auxEnterpriseId, auxEntepriseRol) => {
        if (!response.Status) {
            checkEnterprisePath(auxEnterpriseId, finishOperation, t);
        } else {
            switch (response.Status) {
                case 202:
                    checkEnterprisePath(auxEnterpriseId, finishOperation, t);
                    break;
                case 203:
                case 206:
                    handleUnsubscribedOrPrivacyEnterprise(response.Status, auxEntepriseRol);
                    break;
                default:
                    break;
            };
        };
    };

    const handleUnsubscribedOrPrivacyEnterprise = (code, auxEntepriseRol) => {
        if (auxEntepriseRol == 1) {
            let auxText = code == 203 ? "unsuscribedEnterprise" : "pendingEnterprisePrivacy";
            let auxFunction = code == 203 ? setOpenSubscribe : setOpenPrivacy;
            setSessionItem(auxText, true);
            auxFunction(true);
        } else {
            let auxTitle = code == 203 ? t("chooseEnterpriseNoSubsTitle") : t("chooseEnterpriseNoPrivTitle");
            let auxText = code == 203 ? t("chooseEnterpriseNoSubsText") : t("chooseEnterpriseNoPrivText");
            setNotTitularTitle(auxTitle);
            setNotTitularText(auxText);
            setOpenNotTitular(true);
        };
    };

    return (
        <Grid className={classes.mainDiv}>
            <Alerts alert={alert} setAlert={setAlert}></Alerts>
            <DialogPrivacy
                open={openPrivacy}
                setOpen={setOpenPrivacy}
                enterpriseId={enterpriseId}
                finishOperation={finishOperation}
            />
            <DialogSubscribe
                open={openSubscribe}
                enterpriseId={enterpriseId}
                setOpen={setOpenSubscribe}
            />
            <BasicDialog
                open={openNotTitular}
                setOpen={setOpenNotTitular}
                title={notTitularTitle}
                text={notTitularText}
                action={() => { }}
                closeAction={backToLogin}
                justInfo={true}
            />
            {checkIsNullUndefined(circularProgress) || circularProgress ? (
                <CircularProgress />
            ) : (
                <BackgroundLogin>
                    <Grid container alignItems="center" style={{ marginTop: "2em" }}>
                        <Grid container item xs={12} style={{ margin: "auto" }}>
                            {enterprises.length > 0 ? (
                                <Grid item xs={12} style={{ marginTop: "1em" }}>
                                    <DialogEnterprise
                                        enterprises={enterprises}
                                        manageEnterpriseLogin={manageEnterpriseLogin}
                                        openDirectly={true}
                                        goToCreateEnterprise={goToCreateEnterprise}
                                    />
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                </BackgroundLogin>
            )}
        </Grid>
    );
}
