export const ES2 = {
  //User Account Cancel Page

  accountCancelDisableAccountAsk: "¿Seguro que quieres darte de baja?",
  accountCancelDisableAccountTitle: "Al darte de baja de Correos ID dejarás de tener acceso a todos los servicios que se muestran a continuación:",
  accountCancelDisableAccountListTitle: "Ten en cuenta que:",
  accountCancelDisableAccountList: "Borraremos tu cuenta.",
  accountCancelDisableAccountListEnterprise: "Borraremos las empresas de las que eres titular.",
  accountCancelDisableAccountP1: "Te aconsejamos que antes de eliminar tu cuenta de Correos ID solicites y finalices la baja en los servicios en los que estás registrado. Si aun así sigues prefiriendo eliminar ahora tu cuenta de Correos ID ahora, pincha en Dar de baja.",
  accountCancelDisableAccountP2: "*Tranquilo, tienes {disableAccDays} días para recuperar tu cuenta de Correos ID por si te arrepientes. Podrás recuperar la cuenta de Correos ID, pero el resto de servicios pueden no guardar tus datos durante estos {disableAccDays} días.",
  accountCancelDisableAccountConfirm: "Dar de baja",
  accountCancelDisableAccountLastWarn: "Si pulsas en aceptar confirmarás que quieres darte de baja",

  //Enterprise Delete Page
  enterpriseDeleteEnterpriseTitle: "¿Seguro que quiere borrar la empresa {enterpriseName} ?",
  enterpriseDeleteEnterpriseTitleNoName: "¿Seguro que quiere borrar la empresa?",
  enterpriseDeleteEnterpriseServicesTitle: "Actualmente esta empresa tiene acceso a los servicios que se muestran a continuación:",
  enterpriseDeleteEnterpriseText: "Una vez borrada no podrás recuperar los datos referentes a ella",
  enterpriseDeleteButtonText: "Eliminar Empresa",
  enterpriseDeleteAlertMessageSuccess: "Empresa eliminada con éxito",
  enterpriseDeleteAlertMessageError: "Error al eliminar la empresa",
  enterpriseDeleteAlertLastWarning: "Si pulsas en aceptar confirmarás que quieres borrar la empresa",

  //Privacy page
  privacyMyServices: "Mis servicios",
  privacyAllowRemoveAccess: "Permitir",
  privacyPermissionsManage: "Revisa los términos y condiciones",
  privacyPrivacyManage: "Gestiona tu privacidad",
  privacyUnsuscribe: "Retirar acceso a mis datos",
  privacySuscribe: "Permitir acceso a mis datos",
  privacyDownload: "Descargar mi histórico de privacidad",
  privacySuccessChange: "Privacidad cambiada con éxito",
  privacyWarning: "¡Atención!",
  privacyInfo1: "Vas a retirar el acceso de Mi Buzón a tu cuenta de Correos ID. Deberás conceder acceso de nuevo si quieres volver a acceder a Mi Buzón . Desde el momento que aceptes, dejaremos de compartir tus datos con el servicio.",
  privacyInfo1_PARAM: "Vas a retirar el acceso de {APP} a tu cuenta de Correos ID. Deberás conceder acceso de nuevo si quieres volver a acceder a {APP} . Desde el momento que aceptes, dejaremos de compartir tus datos con el servicio.",
  privacyTyCLink: "Términos y Condiciones",
  privacyPrivacyLink: "Políticas de Privacidad",
  privacySignedTyCPrivacy: "Firmado:",
  privacyInfo2: "Si lo que quieres es que Mi Buzón elimine tu cuenta, podemos ayudarte a gestionar la baja pinchado aquí.",
  noState: "Sin estado",
  privacyIntroText: "Revisa los términos y condiciones, así como las poíticas de privacidad firmadas para cada servicio suscrito.",
  privacyIntroText2: "Gestiona la privacidad de Correos ID. Puedes revisar el estado actual de la protección de datos y modificarlo si así lo requieres.",
  privacyDataProtectionTitle: "Protección de Datos",
  privacyEnableAllText: "Activar todos",
  privacyDisableAllText: "Desactivar todos",
  //Team Page
  holderName: "Nombre",
  holderLastName: "1º Apellido",
  holderSecondLastName: "2º Apellido",
  holderPrefix: "Prefijo",
  holderMobile: "Movil",
  holderEmail: "Email",
  teamPageHolderTitle: "Titular de la cuenta",
  teamPageAuthorizedTitle: "Mis personas autorizadas",
  teamPageAuthorizedName: "Nombre",
  teamPageAuthorizedLastName: "Apellidos",
  teamPageAuthorizedState: "Estado",
  teamPageAuthorizedRol: "Rol",
  teamPageButtonAddEmployee: "Añadir Empleado",
  teamPageButtonChangeHolder: "Cambiar Titular",
  teamPageInsertMail: "Email",
  teamPageInsertNewMail: "Introduzca email y rol",
  teamPageSelectHolder: "Seleccione el nuevo titular",
  teamPageRol: "Rol:",
  teamPageItemAdmin: "Administrador",
  teamPageItemAutorized: "Autorizado",
  teamPageChangeHolder: "Cambiar Titular",
  teamPageInvitationSuccess: "Invitación enviada con éxito",
  teamPageTitularChangeSuccess: "Petición de cambio de titular enviada",
  teamPageTitularEmpty: "La empresa no tiene titular",
  teamPageItemsTitle: "Items de empresa",
  teamPageAddItem: "Añada un item",
  teamPageEditItem: "Modifique el item",
  teamPageItemType: "Tipo de item a añadir",
  teamPageItemValue: "Valor del item",
  teamPageItemError: "Debe introducir un valor",
  teamPageItemEdit: "Modificar item",
  teamPageItemDelete: "Eliminar item",
  teamPageItemDeleteSuccess: "Item eliminado correctamente",
  teamPageItemAddSuccess: "Item añadido correctamente",
  teamPageItemEditSuccess: "Item modificado correctamente",
  revokeAdminPermissions: "Quitar persmisos de administrador",
  revokeAdminPermissionsQuestion: "Si continúas, quitarás permisos de administrador a este empleado, pero seguirá siendo empleado y perteneciendo al equipo",
  revokeAdminConfirm: "Permisos quitados correctamente",
  teamPageNoDocumentEnterprise: "La empresa no tiene número de documento asociado",
  teamPagePutPassword: "Antes de continuar introduzca su contraseña",
  teamPagePutPasswordError: "La contraseña es incorrecta",
  teamPagePutPasswordEmptyError: "La contraseña es obligatoria",
  teamPageAddAdminTitle: "Dar permisos de administrador",
  teamPageAddAdminConfirmMsg: "Se va a enviar un email para que el usuario acepte el rol de administrador.",
  teamPageInvited: "Invitado",
  teamPageSystemUser: "Usuario de sistema",
  teamPageInvitationConfirmCancel: "¿Cancelar invitación?",
  teamPageInvitationCancel: "Cancelar invitación",
  teamPageInvitationCancelSuccess: "Invitación cancelada con éxito",
  teamPageAuthorizedTitularOnly: "Sólo el titular de la empresa puede acceder al contenido de esta pantalla",
  teamPageAddNewEmployee: "Añadir empleado",
  teamPageCreateSystemUser: "Crear usuario de sistema",
  teamPageCreateSystemUserTitle: "Creación de usuario de sistema",
  teamPageCreateSystemUserWarn: "La empresa no está suscrita a ninguna aplicación, no se puede crear un usuario de sistema",
  teamPageCreateSystemUserText1: "Hemos enviado un código de verificación a:",
  teamPageCreateSystemUserText2: "Seleccione aplicación e introduzca el código de verificación y la contraseña para el usuario",
  teamPageCreateSystemUserOtp: "Código de verificación",
  teamPageCreateSystemUserSuccess: "Usuario de sistema creado con éxito",
  teamPageCreateSystemUserApplication: "Aplicación",
  teamPageCreateSystemUserPassword: "Contraseña para el usuario",
  teamPageCreateSystemUserCheckPassword: "Confirmación de contraseña",
  teamPageDeleteUserFromEnterpriseTooltip: "Eliminar usuario",
  teamPageDeleteUserFromEnterpriseText: "¿Quiere eliminar este usuario de la empresa?",
  teamPageDeleteUserFromEnterpriseSucess: "El usuario {user} ya no pertenece a la empresa",
  teamPageSubtitle: "Consulta y gestiona el equipo de la empresa",

  //unifyAccounts Page
  unifyAccountsTitle: "Unificación de cuentas",
  unifyAccountsPreviousInfoTitle: "Información previa",
  unifyAccountsPreviousInfo: "Información que puede ser de ayuda de cada usuario",
  unifyAccountsSubTitle: "Selecciona de entre los datos de usuario que son distintos entre ambas cuentas con cuál de ellos prefieres quedarte:",
  unifyAccountsDataTitle: "Datos de Usuario",
  unifyAccountsData1: "Datos de {user1}",
  unifyAccountsData2: "Datos de {user2}",
  unifyAccountsMultidata: "Ahora selecciona si hay alguno de los emails, teléfonos o direcciones de {user2} que quieres añadir en el perfil de {user1}",
  unifyAccountsMultidataTitle: "Emails, teléfonos y direcciones",
  unifyAccountsEmails1: "Emails de {user1}",
  unifyAccountsEmails2: "Emails de {user2}",
  unifyAccountsPhones1: "Teléfonos de {user1}",
  unifyAccountsPhones2: "Teléfonos de {user2}",
  unifyAccountsAddress1: "Direcciónes de {user1}",
  unifyAccountsAddress2: "Direcciónes de {user2}",
  unifyAccountsInfo1: "Información de {user1}",
  unifyAccountsInfo2: "Información de {user2}",
  unifyAccountsEnterpriseTitle: "Empresas",
  unifyAccountsEnterprise: "Aquí puedes ver las empresas de {user1} y las que se van a unificar del {user2}",
  unifyAccountsEnteprise1: "Empresas de {user1}",
  unifyAccountsEnteprise2: "Empresas de {user2}",
  unifyAccountsServiceTitle: "Servicios",
  unifyAccountsService: "Aquí puedes ver las suscripciones de {user1} y las aplicaciones a las que se va a suscribir de {user2}",
  unifyAccountsService1: "Aplicaciones suscritas de {user1}",
  unifyAccountsService2: "Aplicaciones suscritas de {user2}",
  unifyAccountsNoData: "Este usuario no tiene datos de este tipo",
  unifyAccountsSuccess: "Unificación finalizada",
  unifyAccountsText: "Las dos cuentas se han unificado, ya puedes seguir navegando",
  unifyAccountsRedirectWarn: "Tienes que indicar la pantalla a la que hay que redirigir",
  unifyAccountIncorrectId: "El identificador de usuario proporcionado no corresponde con ninguna cuenta",
  unifyAccountsMobileNoDoc: "Sin documento",
  unifyAccountsMobileuserData: "Escoge el usuario cuyos datos quieras conservar",
  unifyAccountsMobileuserDocument: "Escoge qué documento quieres conservar",
  unifyAccountsMobileuserEmail: "Selecciona los emails de {user2} que quieras incorporar",
  unifyAccountsMobileuserPhone: "Selecciona los teléfonos de {user2} que quieras incorporar",
  unifyAccountsMobileuserAddress: "Selecciona las direcciones de {user2} que quieras incorporar",
  unifyAccountsMobileuserEnterprise: "Estas son todas las empresas que tendrás",
  unifyAccountsMobileuserSubscription: "Estás son todas las aplicaciones a las que estarás suscrito",
  unifyAccountsMobileuserInfo: "Aquí aportamos información útil de cada usuario",
  unifyAccountsMobileUserInfoLogin: "Último login: ",
  unifyAccountsUserInfoLogin: "Último login",

  //Mobile unifyAccouts
  userName: "Nombre",
  userSurname1: "Apellido 1",
  userSurname2: "Apellido 2",
  userbirthDate: "Fecha de nacimiento",
  userNacionality: "Nacionalidad",
  userDocument: "NIF",
  userGender: "Sexo",
  userLanguage: "Idioma",
  stepPage: "Paso {stepPage} de {totalSteps}",
  userInfoMobile: "Información útil",
  userDataMobile: "Datos de usuario",
  emailsMobile: "Emails",
  phonesMobile: "Teléfonos",
  documentsMobile: "Documentos",
  addressMobile: "Direcciones",
  servicesMobile: "Suscripciones",
  enterpriseMobile: "Empresas",
  nextStep: "Siguiente",
  previousStep: "Anterior",
  saveMobile: "Guardar",

  //UploadDocumentModal
  uploadDocumentModalContent: "Por favor, ten en cuenta que si ya existe un documento de la misma tipología en proceso de verificación, esta se perderá.",
  uploadDocumentModalTitle: "Vas a proceder a subir un nuevo documento",
  uploadDocumentFormatUnsupported: "Formato de documento no admitido.",
  uploadDocumentSuccess: "Documento subido correctamente",
  uploadDocumentError: "Hubo un problema al subir el archivo",

  // AtcPage - Stepper
  atcSearchUser: "Buscar usuario",
  atcSelectAccount: "Seleccionar/Crear cuenta",
  atcUserVerify: "Verificar usuario",
  atcUserData: "Datos usuario",
  atcIdentifierField: "Introduce: Dni, Pasaporte, Teléfono o Email",
  atcOtpText: "Para garantizar tu identidad, enviaremos un código:",
  atcSendEmailButton: "ENVIAR EMAIL",
  atcResendEmailButton: "REENVIAR EMAIL",
  atcSendSmsButton: "ENVIAR SMS",
  atcResendSmsButton: "REENVIAR SMS",
  atcRegisterPagePrefix: "Prefijo",
  atcRegisterPagePhone: "Teléfono",
  atcRegisterPageEmail: "Email",
  atcRegisterPageDniNie: "Dni, Pasaporte",
  atcRegisterPageSendOTP: "Introduzca el código enviado",
  atcRegisterPageSendConsentSms: "ENVIAR SMS TYC",
  atcRegisterPageResendConsentSms: "REENVIAR SMS TYC",
  atcCheckOtpCode: "COMPROBAR CÓDIGO",
  atcAccept: "ACEPTAR",
  atcClose: "CERRAR",
  atcCancel: "CANCELAR",
  atcCreate: "CREAR",
  atcModify: "MODIFICAR",
  atcVerifyDocumentIdentifier: "VERIFICAR DNI/PASAPORTE",
  atcPhoneError: "El teléfono es obligatorio, introduce un formato válido",
  atcIdentifierDocumentError: "El dni/pasaporte es obligatorio, introduce un formato válido",
  atcEmailError: "El email es obligatorio, introduce un formato válido",
  atcNewAccount: "Crear nueva cuenta",
  atcRadioSelectAccount: 'Seleccione su cuenta o la opción "Nueva Cuenta"',
  atcModalTitle: "Datos verificados",
  atcModalSubtitle: "Por favor, seleccione su cuenta",
  atcModalEmailTitle: "Direcciones de Email:",
  atcModalPhoneTitle: "Números de teléfono:",

  //DialogMissingData
  dialogMissingDataText: "Hay acciones requeridas para el correcto funcionamiento de la aplicación:",
  dialogMissingDataTextTyC: "Para ello antes debes aceptar la privacidad y los términos y condiciones",
  dialogMissingDataTextDocument: "Necesitas verificar tu documento identificativo lo que implica que no puedes continuar a partir de aquí. Para poder verificarlo te recomendamos que te dirijas a tu oficina de correos más cercana.",
  dialogMissingDataTextNoHolder: "La empresa no tiene los datos obligatorios necesarios, ponte en contacto con el titular para poder continuar.",
  dialogMissingDataunsuscribedEnterprise: "Es necesario suscribirse a la aplicación",
  dialogMissingDatapendingEnterprisePrivacy: "Para ello antes debes aceptar la privacidad y los términos y condiciones",
  dialogMissingDataincompleteUserData: "Rellenar datos de usuario obligatorios",
  dialogMissingDataincompleteEnterpriseData: "Rellenar datos de empresa obligatorios",
  dialogMissingDatapendingEnterprise: "Crear una empresa",
  dialogMissingDataverifyEmail: "Añadir un email verificado",
  dialogMissingDataverifyPhone: "Añadir un teléfono verificado",
  dialogMissingDataverifyAddress: "Añadir una dirección verificada",
  dialogMissingUserDataText: "Los datos de usuario requeridos para el funcionamiento del servicio no son correctos o están incompletos. Por favor actualice sus datos.",
  dialogMissingEnterpriseDataText: "Es necesaria la creación de una empresa para el correcto funcionamiento de este servicio. Por favor cree su empresa.",
  dialogMissingVerificationData: "Necesita verificar sus datos para el correcto funcionamiento del servicio",

  // Redirect Page

  redirectPageMissingParams: "Faltan parámetros",
  redirectPagePageNotFound: "La página no existe",

  // EnterpriseOauthLogin Page

  enterpriseOauthLoginPageError: "Faltan parámetros",
  redirectPageIdEnterpriseNotFound: "Se necesita el Id de empresa para acceder a esa página",
  redirectPageNotUserEnterprise: "La empresa indicada no pertenece al usuario",

  // ChooseEnterprise Page

  chooseEnterpriseNoSubsTitle: "Empresa no suscrita",
  chooseEnterpriseNoSubsText: "La empresa no está suscrita a la aplicación, ponte en contacto con el titular para que la suscriba y poder continuar",
  chooseEnterpriseNoPrivTitle: "Privacidad sin firmar",
  chooseEnterpriseNoPrivText: "La empresa no tiene firmada la privacidad, ponte en contacto con el titular para que la firme y poder continuar",
  chooseEnterpriseNoDataTitle: "Datos requeridos",
  chooseEnterpriseNoDataText: "La empresa no tiene todos los datos necesarios, ponte en contacto con el titular para que los rellene y poder continuar",

  // ResetPass Warn

  expiredPassTitle: "Contraseña caducada",
  expiredPassText: "La contraseña ha caducado, por favor, crea una nueva",

  //UserVerifications2FA
  verifications2FATitle: "Configura tu doble factor de autenticación",
  verifications2FAImageText: "Aquí puedes gestionar tus servicios con doble factor de autenticación. Activa o desactiva el doble factor de autenticación para cada uno de los servicios que tengas contratados teniendo en cuenta que hay servicios que no podrás desactivar porque lo exigen por normativa.",
  verifications2FAConfigText: "Servicios contratados",
  verifications2FAConfigNoServiceText: "No estás suscrito a ningún servicio",
  verifications2FASetPhone: "Configura tu teléfono",
  verifications2FASetEmailTitle: "Confirma tu identidad",
  verifications2FASetEmailText: "Mandaremos un código de verificación a tu email",
  verifications2FASetEmailConfText: "Mandaremos un código de verificación a tu email para confirmar tu identidad",
  verifications2FASetEmailOtp: "Código de verificación",
  verifications2FASetPhoneTitle: "Configura tu teléfono",
  verifications2FASetPhoneText: "Mandaremos un código de verificación a tu teléfono",
  verifications2FASetPhoneOtp: "Código de verificación",
  verifications2FASetPhonConfirmation: "Ya tienes configurado un teléfono para el doble factor de autenticación. Si estabas intentando hacer login en un servicio, por favor, vuelve a intentarlo.",
  verifications2FAConfigSuccess: "Tu configuración para el doble factor de autenticación se ha actualizado correctamente",

  // Login2FA

  login2FASetPhoneTitle: "Configura tu teléfono",
  login2FASetPhoneText: "Esta aplicación requiere un doble factor de autenticación y necesitas configurar tu teléfono. Para ello accede a la configuración de tu cuenta pulsando en 'Aceptar'.",
  login2FASendPhoneTitle: "Segundo factor de autenticación",
  login2FASendPhoneText: "Esta aplicación requiere un segundo factor de autenticación, introduce el código de verificación que enviaremos a tu teléfono.",
  login2FASetPhoneOtp: "Código de verificación",
  login2FASetPhoneError: "No se ha podido validar el teléfono. Por favor, inténtalo de nuevo.",
  
  // Oauth - ACM
  loginACMTitle: "Inicio de sesión erróneo",
  loginACMText: "Ha habido un problema con los datos aportados, por favor inténtalo de nuevo",
  generalACMTitle: "Atención",
  generalACMText: "Para poder acceder a la aplicación debes volver a iniciar sesión",

  // GeneralWarning
  generalWarningPassText: "Se ha producido un error al cambiar la contraseña. Si has recibido el código de validación quizá lo has introducido mal, o quizá podría ser que el {dataType} que has indicado sea incorrecto, en cuyo caso asegúrate de haberlo escrito bien.",
  generalWarningRegistryText: "Se ha producido un error al crear la cuenta. Si has recibido el código de validación quizá lo has introducido mal, o quizá podría ser que la cuenta de e-mail que has indicado ya esté registrada en CorreosID, en cuyo caso deberías recuperar la contraseña.",
  generalWarningVerifyText: "Se ha producido un error al verificar el {dataType}. Si has recibido el código de validación quizá lo has introducido mal, o quizá podría ser que el {dataType} que has indicado ya esté verificado en CorreosID.",
};
